import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { store } from './store/store';
import { Provider } from 'react-redux';

const TokicLogo = require('./assets/TokicGrupaLogo1.png');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <div className='hero-logo margin-top-3 margin-bottom-3'>
          <img className='hero-logo__image' src={TokicLogo} alt='Tokic logo' />
        </div>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
