import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Korisnik } from '../../common/types/types';

const initialState: Korisnik = {
  id: null,
  username: '',
  position: '',
  role: '',
};

const korisnikSlice = createSlice({
  name: 'korisnik',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setKorisnik: (state, action: PayloadAction<Korisnik>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setKorisnik } = korisnikSlice.actions;

export default korisnikSlice.reducer;
