import React from 'react';
import { useLocation } from 'react-router';
import { Employee } from '../common/types/types';
import DetailsComponent from '../components/DetailsComponent';
import { useAppSelector } from '../store/hooks/hooks';

const Details = () => {
  const location = useLocation();

  let employeeId = location.state.from;
  //   if (employeeId !== null) employeeId = employeeId.form;
  //   else employeeId = null;

  const { recivedEmployees } = useAppSelector((state) => state.employees);

  let locEmployeeDetails: any;
  recivedEmployees.forEach((e) => {
    if (e.obj_id === employeeId) locEmployeeDetails = e;
    return locEmployeeDetails;
  });

  return <DetailsComponent employeeDetails={locEmployeeDetails} />;
};

export default Details;
