import { PlanObilaska, SelectOption } from '../types/types';

export const statusZahtjeva: SelectOption[] = [
  { value: 1, label: 'U tijeku' },
  { value: 2, label: 'Završen' },
  { value: 3, label: 'Odbijen' },
  { value: 4, label: 'Na čekanju' },
];

// export const karticaOptions: Array<string> = [
//   'glavniUprava',
//   'glavniLDC',
//   'sporedniLDC',
//   'podrum',
//   'rampaZaParking',
// ];
export const karticaOptions: Array<string> = [
  'Glavni ulaz u upravnu zgradu',
  'Ulaz u upravu Aluminco',
  'Glavni ulaz u LDC',
  'Sporedni ulaz u LDC (iz upravne zgrade)',
  'Ulaz u podrum',
  'Ulazna rampa za parking',
];

export const racunaloOptions: Array<string> = ['Stolno računalo', 'Laptop'];

export const infProgramiOptions: Array<string> = ['Microsoft Office'];

export const sluzbeniMobitelOptions: Array<string> = [
  '1.	Uprava Društva i izvršni direktori',
  '2.	Direktori i Zamjenici direktora, Voditelji odjela i Zamjenici voditelja odjela',
  '3.	Područni Voditelji, Voditelj područja, Voditelj dostavne službe, Voditelj kontakt centra, Zamjenik voditelja skladišta',
  '4.	Voditelj poslovnice',
  '5. Ostali korisnici',
];
export const sluzbeniMobitelOptionsCheck: Array<string> = ['Potreban broj'];

export const sluzbeniAutomobilOptions: Array<string> = [
  '1. kategorija (Članovi uprave)',
  '2. kategorija (Izvršni direktori)',
  '3. kategorija (Direktori, voditelji sektora (većih odjela))',
  '4. kategorija (Voditelji odjela/ timova/ regija/ područja/ terenski komercijalisti i ostali kojima je zbog prirode posla potrebno službeno vozilo)',
];

export const sluzbeniAutomobilOptions2: Array<string> = ['do 20.000 km', '20.000 - 30.000 km', '30.000 - 40.000 km'];
export const sluzbeniAutomobilOptionsCheck: Array<string> = ['ENC', 'kartica za gorivo', 'parkirno mjesto'];

export const sluzbenaVisaKarticaOptions: Array<string> = [
  '1. kategorija (Članovi uprave)',
  '2. kategorija (Izvršni direktori, direktori)',
  '3. kategorija (Voditelji odjela, zamjenici direktora)',
  '4. kategorija (Voditelji kategorija/ ključnih kupaca/projekata u prodaji/projekata u održavanju/ureda Uprave/područni voditelji/Dostavljači/Servisni tehničari i ostali kojima je zbog prirode posla potrebna Visa kartica)',
];

export const dmsOptions: Array<string> = ['Raspisivanje troškova računa', 'Odobravanje računa', 'Izrada narudžbenica'];

export const ostaloOptions: Array<string> = ['Radni stol i stolica', 'Vizitka'];

export const upoznavanjeOptions1: { id: number; value: string }[] = [
  { id: 1, value: '1 h' },
  { id: 2, value: '1 dan' },
  { id: 3, value: '2 dana' },
  { id: 4, value: '5 dana' },
  { id: 5, value: '10 dana' },
];

export const upoznavanjeOptions2: { id: number; value: string }[] = [
  { id: 1, value: '0.5h' },
  { id: 2, value: '1h' },
  { id: 3, value: '2h' },
  { id: 4, value: '3h' },
  { id: 5, value: '1 dan' },
];
export const biOptions: { id: number; value: string }[] = [
  { id: 1, value: '---Odaberi---' },
  { id: 2, value: 'Prodaja' },
  { id: 3, value: 'Nabava' },
  { id: 4, value: 'Zaliha' },
  { id: 5, value: 'Potraživanja' },
  { id: 6, value: 'Obveze' },
  { id: 7, value: 'Glavna knjiga' },
];

// export const odjeli: { id: number; value: string }[] = [
//   { id: 1, value: 'LDC' },
//   { id: 2, value: 'Velika lokacija' },
//   { id: 3, value: 'Mala lokacija' },
//   { id: 4, value: 'Kontinemtalna hrvatska' },
//   { id: 5, value: 'Jadranska Hrvatska' },
//   { id: 6, value: 'Online kanali prodaje' },
//   { id: 7, value: 'Call centar' },
//   { id: 9, value: 'Upravljanje karegorijama' },
//   { id: 10, value: 'Upravljanje cijenama' },
//   { id: 11, value: 'Matični podaci' },
//   { id: 12, value: 'Podrška u nabavi' },
//   { id: 13, value: 'Upravljanje zalihom' },
//   { id: 14, value: 'Transport' },
//   { id: 15, value: 'WMS' },
//   { id: 16, value: 'Gospodarski program' },
//   { id: 17, value: 'Alati i servisna oprema' },
//   { id: 18, value: 'ACC' },
//   { id: 19, value: 'Industrijski program' },
//   { id: 20, value: 'TEC' },
//   { id: 21, value: 'Ključni kupci i natječaji' },
//   { id: 22, value: 'Izvozna prodaja' },
//   { id: 23, value: 'Poslovanje guma' },
//   { id: 24, value: 'Marketing' },
//   { id: 25, value: 'CRM' },
//   { id: 26, value: 'Korporativno upravljanje' },
//   { id: 27, value: 'Ljudski resursi' },
//   { id: 28, value: 'Informatika' },
//   { id: 29, value: 'ICT infrastruktura' },
//   { id: 30, value: 'DTI' },
//   { id: 31, value: 'Poslovna Inteligencija' },
//   { id: 32, value: 'Održavanje i investicije' },
//   { id: 33, value: 'Financije' },
//   { id: 34, value: 'Kontroling' },
// ];

// export const planObilaska: { id: string; value: string }[] = [
//   { id: 'ldc', value: 'Skladište' },
//   { id: 'mala_lokacija', value: 'Mala poslovnica' },
//   { id: 'velika_lokacija', value: 'Velika poslovnica' },
//   { id: 'jadranska_hrvatska', value: 'Jadranska Hrvatska' },
//   { id: 'kontinental_hrvatska', value: 'Kontinentalna Hrvatska' },
//   // {id: 'lean', value: 'lean'},
//   { id: 'upravljanje_kategorijama', value: 'Upravljanje kategorijama' },
//   { id: 'upravljanje_cijenima', value: 'Upravljanje cijenama' },
//   { id: 'WMS_tim', value: 'WMS tim' },
//   { id: 'transport', value: 'Transport' },
//   { id: 'upravljanje_zalihom', value: 'Upravljanje zalihom' },
//   { id: 'izvozna_prodaja', value: 'Izvozna prodaja' },
//   { id: 'gospodarski_program', value: 'Gospodarski program' },
//   // {id: 'usluge_u_prodaju', value: 'usluge_u_prodaju'},
//   { id: 'klucni_kupci', value: 'Ključni kupci' },
//   { id: 'online_kanali_prodaje', value: 'Online kanali prodaje' },
//   { id: 'poslovna_inteligencija', value: 'Poslovna inteligencija' },
//   { id: 'ljudksi_resursi', value: 'Ljudski resursi' },
//   { id: 'call_center', value: 'Call centar' },
//   { id: 'DTI', value: 'DTI' },
//   { id: 'informatika', value: 'Informatika' },
//   { id: 'marketing', value: 'Marketing' },
//   { id: 'financije', value: 'Financije' },
//   { id: 'korporativniUpravlje', value: 'Korporativno upravlje' },
//   { id: 'odrzavanje', value: 'Održavanje' },
//   // {id: 'odjel_sredisnje_nabave', value: 'odjel_sredisnje_nabave'},
//   { id: 'razvoj_poslovanja', value: 'Razvoj poslovanja' },
//   { id: 'poslovni_guma', value: 'Poslovni guma' },
//   { id: 'ICT_infrastruktura', value: 'ICT infrastruktura' },
//   { id: 'kontroling', value: 'Kontroling' },
//   // {id: 'odjel_distribrucije', value: 'odjel_distribrucije'},
//   { id: 'maticni_podaci', value: 'Matični podaci' },
//   { id: 'podrska_u_nabavi', value: 'Podrška u nabavi' },
//   { id: 'alati_servisna_oprema', value: 'Alati i servisna oprema' },
//   { id: 'acc', value: 'ACC servisi' },
//   { id: 'industrijski_program', value: 'Industrijski program' },
//   { id: 'tec', value: 'TEC ( servis i kontrola kvalitete)' },
//   { id: 'crm', value: 'CRM' },
// ];
