import moment from 'moment';
import { statusZahtjeva } from '../initialValues/initialValues';

export function pretvoriStatusZahtjevaUFormatTablice(value: number | null, undefinedText: String): string {
  return value ? (statusZahtjeva.find((val) => val.value === value)?.label as string) : (undefinedText as string);
}

export function pretvoriDatumUFormatTablice(value: undefined | null | string, undefinedText: String): string | JSX.Element {
  return value ? (moment(value).format('DD. MM. YYYY') as string) : (undefinedText as string);
}

export const searchRequestDate = (value: Date | undefined | null) => {
  if (value != null) {
    const date = new Date(value);
    const month = date.toLocaleString('en-EU', { month: '2-digit' });
    const day = date.toLocaleString('en-EU', { day: '2-digit' });
    const year = date.getFullYear();
    const fullDate: string = year + '-' + month + '-' + day;
    return fullDate;
  }
};
