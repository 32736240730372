import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import useAxiosPrivate from '../common/hooks/useAxiosPrivate';
import {
  biOptions,
  dmsOptions,
  infProgramiOptions,
  karticaOptions,
  racunaloOptions,
  sluzbenaVisaKarticaOptions,
  sluzbeniAutomobilOptions,
  sluzbeniAutomobilOptions2,
  sluzbeniAutomobilOptionsCheck,
  sluzbeniMobitelOptions,
  sluzbeniMobitelOptionsCheck,
  upoznavanjeOptions1,
  upoznavanjeOptions2,
} from '../common/initialValues/initialValues';
import { useAppSelector } from '../store/hooks/hooks';
import { CalendarValue } from '../common/types/types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { searchRequestDate } from '../common/utils/HelperFunction';

// CHECKBOX ARRAYS
let karticaArray: any[] = [];
let racunaloArray: any[] = [];
let infProgramiArray: any[] = [];
let biArray: any[] = [];
let sluzbeniAutomobilArray: any[] = [];
let sluzbeniAutomobilCheckArray: any[] = [];
let sluzbeniMobitelArray: any[] = [];
let sluzbeniMobitelCheckArray: any[] = [];
let sluzbenaVisaKarticaArray: any[] = [];
let ostaloArray: any[] = [];
let dmsArray: any[] = [];
let emailGrupeArray: any[] = [];
let sharefolderPristupArray: any[] = [];
let radniStolArray: any[] = [];
let vizitkaArray: any[] = [];

//-------------------------------------------------
let LDCArray: any[] = [];
let malaLokacijaArray: any[] = [];
let velikaLokacijaArray: any[] = [];
let kontinentalnaHrvatskaArray: any[] = [];
let jadranskaHrvatskaArray: any[] = [];
// let leanArray: any[] = [];
let upravljanjeKategorijamaArray: any[] = [];
let upravljanjeCijenamaArray: any[] = [];
let WMSArray: any[] = [];
let transportArray: any[] = [];
let upravljanjeZalihomArray: any[] = [];
let izvoznaProdajaArray: any[] = [];
let gospodarskiProgramArray: any[] = [];
// let uslugeProdajuArray: any[] = [];
let kljucniKupciArray: any[] = [];
let onlineKanaliProdajeArray: any[] = [];
let poslovnaInteligencijaArray: any[] = [];
let ljudskiResursiArray: any[] = [];
let callCentarArray: any[] = [];
let DTIArray: any[] = [];
let informatikaArray: any[] = [];
let marketingArray: any[] = [];
let racunovodstvoArray: any[] = [];
let korporativnoUpravljanjeArray: any[] = [];
let odrzavanjeInvesticijeArray: any[] = [];
// let odjel_sredisnje_nabaveArray: any[] = [];
// let razvoj_poslovanjaArray: any[] = [];
let poslovanje_gumaArray: any[] = [];
let ICT_infrastrukturaArray: any[] = [];
let kontrolingArray: any[] = [];
// let odjel_distribucijeArray: any[] = [];
let maticniPodaciArray: any[] = [];
let podrskaNabaviArray: any[] = [];
let alatiServisnaOpremaArray: any[] = [];
let accArray: any[] = [];
let industrijskiProgramArray: any[] = [];
let tecArray: any[] = [];
let crmArray: any[] = [];

const InputListPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const korisnik = useAppSelector((state) => state.korisnik);

  const [loading, setLoading] = useState<boolean>(false);
  const [stranica, setStranica] = useState<boolean>(false);

  const arrowLeft = require('../assets/icons/arrowLeft2.png');
  const iconInfo = require('../assets/icons/info.png');
  // const prevPage = require('../assets/icons/prevPage.png');
  // const nextPage = require('../assets/icons/nextPage.png');

  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // TXT DATA
  const [employeename, setEmployeename] = useState('');
  const [employeeposition, setEmployeeposition] = useState('');
  const [employeedepartment, setEmployeedepartment] = useState('');
  const [dateofemployment, setDateofemployment] = useState<CalendarValue>(new Date());
  const [buddy, setBuddy] = useState('');
  const [buddyposition, setBuddyposition] = useState('');
  const [buddyemail, setBuddyemail] = useState('');
  const [racunaloOstalo, setRacunaloOstalo] = useState('');
  const [infProgramiOstalo, setInfProgramiOstalo] = useState('');
  const [infProgramiNavision, setInfProgramiNavision] = useState('');
  const [biOstalo, setBiOstalo] = useState('');
  // const [biZaposlenik, setBiZaposlenik] = useState('');
  const [emailGrupe, setEmailGrupe] = useState('');
  const [sharefolderPristup, setSharefolderPristup] = useState('');
  const [ostaloOstalo, setOstaloOstalo] = useState('');
  const [sluzbeniMobitelData, setSluzbeniMobitelData] = useState('');
  const [sluzbeniMobitelOstalo, setSluzbeniMobitelOstalo] = useState('');
  const [sluzbeniAutomobilData, setSluzbeniAutomobilData] = useState('');
  const [sluzbeniAutomobilData2, setSluzbeniAutomobilData2] = useState('');
  const [sluzbenaVisaKarticaData, setSluzbenaVisaKarticaData] = useState('');
  const [radniStolData, setRadniStolData] = useState('');
  const [vizitkaData, setVizitkaData] = useState('');
  //---------------------------------------------------------------------------
  const [LDCData, setLDCData] = useState('');
  const [malaLokacijaData, setMalaLokacijaData] = useState('');
  const [velikaLokacijaData, setVelikaLokacijaData] = useState('');
  const [malaLokacijaDataPoslovnica, setMalaLokacijaDataPoslovnica] = useState('');
  const [velikaLokacijaDataPoslovnica, setVelikaLokacijaDataPoslovnica] = useState('');
  const [kontinentalnaHrvatskaData, setKontinentalnaHrvatskaData] = useState('');
  const [jadranskaHrvatskaData, setJadranskaHrvatskaData] = useState('');
  // const [leanData, setLeanData] = useState('');
  const [upravljanjeKategorijamaData, setUpravljanjeKategorijamaData] = useState('');
  const [upravljanjeCijenamaData, setUpravljanjeCijenamaData] = useState('');
  const [WMSData, setWMSData] = useState('');
  const [transportData, setTransportData] = useState('');
  const [upravljanjeZalihomData, setUpravljanjeZalihomData] = useState('');
  const [izvoznaProdajaData, setIzvoznaProdajaData] = useState('');
  const [gospodarskiProgramData, setGospodarskiProgramData] = useState('');
  // const [uslugeProdajuData, setUslugeProdajuData] = useState('');
  const [kljucniKupciData, setKljucniKupciData] = useState('');
  const [onlineKanaliProdajeData, setOnlineKanaliProdajeData] = useState('');
  const [poslovnaInteligencijaData, setPoslovnaInteligencijaData] = useState('');
  const [ljudskiResursiData, setLjudskiResursiData] = useState('');
  const [callCentarData, setCallCentarData] = useState('');
  const [DTIData, setDTIData] = useState('');
  const [informatikaData, setInformatikaData] = useState('');
  const [marketingData, setMarketingData] = useState('');
  const [racunovodstvoData, setRacunovodstvoData] = useState('');
  const [korporativnoUpravljanjeData, setKorporativnoUpravljanjeData] = useState('');
  const [odrzavanjeInvesticijeData, setOdrzavanjeInvesticijeData] = useState('');
  // const [odjel_sredisnje_nabaveData, setOdjel_sredisnje_nabaveData] = useState('');
  // const [razvoj_poslovanjaData, setRazvoj_poslovanjaData] = useState('');
  const [poslovanje_gumaData, setPoslovanje_gumaData] = useState('');
  const [ICT_infrastrukturaData, setICT_infrastrukturaData] = useState('');
  const [kontrolingData, setKontrolingData] = useState('');
  // const [odjel_distribucijeData, setOdjel_distribucijeData] = useState('');
  const [maticniPodaciData, setMaticniPodaciData] = useState('');
  const [podrskaNabaviData, setPodrskaNabaviData] = useState('');
  const [alatiServisnaOpremaData, setAlatiServisnaOpremaData] = useState('');
  const [accData, setAccData] = useState('');
  const [industrijskiProgramData, setIndustrijskiProgramData] = useState('');
  const [tecData, setTecData] = useState('');
  const [crmData, setCrmData] = useState('');

  // RADIO BUTTONS
  const [kartica, setKartica] = useState(false);
  const [racunalo, setRacunalo] = useState(false);
  const [infProgrami, setInfProgrami] = useState(false);
  const [bi, setBi] = useState(false);
  const [email, setEmail] = useState(false);
  const [sharefolder, setSharefolder] = useState(false);
  const [sluzbeniAutomobil, setSluzbeniAutomobil] = useState(false);
  const [sluzbeniMobitel, setSluzbeniMobitel] = useState(false);
  const [sluzbenaVisaKartica, setSluzbenaVisaKartica] = useState(false);
  const [ostalo, setOstalo] = useState(false);
  const [dms, setDms] = useState(false);
  //---------------------------------------------------------------------------
  const [LDC, setLDC] = useState(false);
  const [malaLokacija, setMalaLokacija] = useState(false);
  const [velikaLokacija, setVelikaLokacija] = useState(false);
  const [kontinentalnaHrvatska, setKontinentalnaHrvatska] = useState(false);
  const [jadranskaHrvatska, setJadranskaHrvatska] = useState(false);
  // const [lean, setLean] = useState(false);
  const [upravljanjeKategorijama, setUpravljanjeKategorijama] = useState(false);
  const [upravljanjeCijenama, setUpravljanjeCijenama] = useState(false);
  const [WMS, setWMS] = useState(false);
  const [transport, setTransport] = useState(false);
  const [upravljanjeZalihom, setUpravljanjeZalihom] = useState(false);
  const [izvoznaProdaja, setIzvoznaProdaja] = useState(false);
  const [gospodarskiProgram, setGospodarskiProgram] = useState(false);
  // const [uslugeProdaju, setUslugeProdaju] = useState(false);
  const [kljucniKupci, setKljucniKupci] = useState(false);
  const [onlineKanaliProdaje, setOnlineKanaliProdaje] = useState(false);
  const [poslovnaInteligencija, setPoslovnaInteligencija] = useState(false);
  const [ljudskiResursi, setLjudskiResursi] = useState(false);
  const [callCentar, setCallCentar] = useState(false);
  const [DTI, setDTI] = useState(false);
  const [informatika, setInformatika] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [racunovodstvo, setRacunovodstvo] = useState(false);
  const [korporativnoUpravljanje, setKorporativnoUpravljanje] = useState(false);
  const [odrzavanjeInvesticije, setOdrzavanjeInvesticije] = useState(false);
  // const [odjel_sredisnje_nabave, setOdjel_sredisnje_nabave] = useState(false);
  // const [razvoj_poslovanja, setRazvoj_poslovanja] = useState(false);
  const [poslovanje_guma, setPoslovanje_guma] = useState(false);
  const [ICT_infrastruktura, setICT_infrastruktura] = useState(false);
  const [kontroling, setKontroling] = useState(false);
  // const [odjel_distribucije, setOdjel_distribucije] = useState(false);
  const [maticniPodaci, setMaticniPodaci] = useState(false);
  const [podrskaNabavi, setPodrskaNabavi] = useState(false);
  const [alatiServisnaOprema, setAlatiServisnaOprema] = useState(false);
  const [acc, setAcc] = useState(false);
  const [industrijskiProgram, setIndustrijskiProgram] = useState(false);
  const [tec, setTec] = useState(false);
  const [crm, setCrm] = useState(false);

  //IsSelectedProvjere
  const [isAllSelected, setIsAllSelected] = useState({
    kartica: false,
    radniStol: false,
    racunalo: false,
    email: false,
    infProgrami: false,
    BI: false,
    sharefolder: false,
    DMS: false,
    mobitel: false,
    visa: false,
    vizitka: false,
    automobil: false,
    ostalo: false,
  });
  const isAnyFalse = () => {
    return (
      employeename === '' ||
      employeeposition === '' ||
      employeedepartment === '' ||
      buddy === '' ||
      buddyposition === '' ||
      buddyemail === '' ||
      Object.values(isAllSelected).some((value) => value === false)
    );
  };
  console.log(isAnyFalse());

  // FUNCTIONS

  const back = () => {
    navigate(-1);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // SUBMIT EVENT
  const handleSubmit = async (event: any) => {
    setDisabledButton(true);
    if (racunaloOstalo !== '') racunaloArray.push(...racunaloOstalo.split(';'));
    if (ostaloOstalo !== '') ostaloArray.push(...ostaloOstalo.split(';'));
    if (infProgramiNavision !== '') infProgramiArray.push(`Navision (rola): ${infProgramiNavision}`);
    if (biOstalo !== '') biArray.push(...biOstalo.split(';'));
    // if (biZaposlenik !== '') biArray.push(`Dodjeliti prava kakva ima i zaposlenik: ${biZaposlenik}`);
    if (infProgramiOstalo !== '') infProgramiArray.push(...infProgramiOstalo.split(';'));
    if (emailGrupe !== '') emailGrupeArray.push(...emailGrupe.split(';'));
    if (sharefolderPristup !== '') sharefolderPristupArray.push(...sharefolderPristup.split(';'));
    if (sluzbeniMobitelData !== '') {
      sluzbeniMobitelArray.push(sluzbeniMobitelData);
      sluzbeniMobitelArray.push(...sluzbeniMobitelCheckArray);
    }
    if (sluzbeniMobitelOstalo !== '') sluzbeniMobitelArray.push(...sluzbeniMobitelOstalo.split(';'));
    if (sluzbeniAutomobilData !== '') {
      sluzbeniAutomobilArray.push(sluzbeniAutomobilData);
      sluzbeniAutomobilArray.push(sluzbeniAutomobilData2);
      sluzbeniAutomobilArray.push(...sluzbeniAutomobilCheckArray);
    }
    if (sluzbenaVisaKarticaData !== '') sluzbenaVisaKarticaArray.push(sluzbenaVisaKarticaData);
    if (radniStolData !== '') radniStolArray.push(radniStolData);
    if (vizitkaData !== '') vizitkaArray.push(vizitkaData);
    //-----------------------------------------------------------------------------------------------------
    if (LDCData !== '') LDCArray.push(...LDCData.split(';'));
    if (malaLokacijaDataPoslovnica !== '') malaLokacijaArray.push(malaLokacijaDataPoslovnica);
    if (velikaLokacijaDataPoslovnica !== '') velikaLokacijaArray.push(velikaLokacijaDataPoslovnica);
    if (malaLokacijaData !== '') malaLokacijaArray.push(...malaLokacijaData.split(';'));
    if (velikaLokacijaData !== '') velikaLokacijaArray.push(...velikaLokacijaData.split(';'));
    if (kontinentalnaHrvatskaData !== '') kontinentalnaHrvatskaArray.push(...kontinentalnaHrvatskaData.split(';'));
    if (jadranskaHrvatskaData !== '') jadranskaHrvatskaArray.push(...jadranskaHrvatskaData.split(';'));
    // if (leanData !== '') leanArray.push(...leanData.split(';'));
    if (upravljanjeKategorijamaData !== '') upravljanjeKategorijamaArray.push(...upravljanjeKategorijamaData.split(';'));
    if (upravljanjeCijenamaData !== '') upravljanjeCijenamaArray.push(...upravljanjeCijenamaData.split(';'));
    if (WMSData !== '') WMSArray.push(...WMSData.split(';'));
    if (transportData !== '') transportArray.push(...transportData.split(';'));
    if (upravljanjeZalihomData !== '') upravljanjeZalihomArray.push(...upravljanjeZalihomData.split(';'));
    if (izvoznaProdajaData !== '') izvoznaProdajaArray.push(...izvoznaProdajaData.split(';'));
    if (gospodarskiProgramData !== '') gospodarskiProgramArray.push(...gospodarskiProgramData.split(';'));
    // if (uslugeProdajuData !== '') uslugeProdajuArray.push(...uslugeProdajuData.split(';'));
    if (kljucniKupciData !== '') kljucniKupciArray.push(...kljucniKupciData.split(';'));
    if (onlineKanaliProdajeData !== '') onlineKanaliProdajeArray.push(...onlineKanaliProdajeData.split(';'));
    if (poslovnaInteligencijaData !== '') poslovnaInteligencijaArray.push(...poslovnaInteligencijaData.split(';'));
    if (ljudskiResursiData !== '') ljudskiResursiArray.push(...ljudskiResursiData.split(';'));
    if (callCentarData !== '') callCentarArray.push(...callCentarData.split(';'));
    if (DTIData !== '') DTIArray.push(...DTIData.split(';'));
    if (informatikaData !== '') informatikaArray.push(...informatikaData.split(';'));
    if (marketingData !== '') marketingArray.push(...marketingData.split(';'));
    if (racunovodstvoData !== '') racunovodstvoArray.push(...racunovodstvoData.split(';'));
    if (korporativnoUpravljanjeData !== '') korporativnoUpravljanjeArray.push(...korporativnoUpravljanjeData.split(';'));
    if (odrzavanjeInvesticijeData !== '') odrzavanjeInvesticijeArray.push(...odrzavanjeInvesticijeData.split(';'));
    // if (odjel_sredisnje_nabaveData !== '') odjel_sredisnje_nabaveArray.push(...odjel_sredisnje_nabaveData.split(';'));
    // if (razvoj_poslovanjaData !== '') razvoj_poslovanjaArray.push(...razvoj_poslovanjaData.split(';'));
    if (poslovanje_gumaData !== '') poslovanje_gumaArray.push(...poslovanje_gumaData.split(';'));
    if (ICT_infrastrukturaData !== '') ICT_infrastrukturaArray.push(...ICT_infrastrukturaData.split(';'));
    if (kontrolingData !== '') kontrolingArray.push(...kontrolingData.split(';'));
    // if (odjel_distribucijeData !== '') odjel_distribucijeArray.push(...odjel_distribucijeData.split(';'));
    if (maticniPodaciData !== '') maticniPodaciArray.push(...maticniPodaciData.split(';'));
    if (podrskaNabaviData !== '') podrskaNabaviArray.push(...podrskaNabaviData.split(';'));
    if (alatiServisnaOpremaData !== '') alatiServisnaOpremaArray.push(...alatiServisnaOpremaData.split(';'));
    if (accData !== '') accArray.push(...accData.split(';'));
    if (industrijskiProgramData !== '') industrijskiProgramArray.push(...industrijskiProgramData.split(';'));
    if (tecData !== '') tecArray.push(...tecData.split(';'));
    if (crmData !== '') crmArray.push(...crmData.split(';'));

    try {
      event.preventDefault();
      let requestDate = searchRequestDate(dateofemployment as Date);
      setLoading(true);
      await axiosPrivate
        .post('/employee/', {
          userName: korisnik.username,
          userPosition: korisnik.position,
          date: new Date().toLocaleString(),

          employeename: employeename,
          employeeposition: employeeposition,
          employeedepartment: employeedepartment,
          dateofemployment: requestDate,
          buddy: buddy,
          buddyposition: buddyposition,
          buddyemail: buddyemail,
          card: karticaArray,
          computer: racunaloArray,
          softwares: infProgramiArray,
          intelligence: biArray,
          emailgroups: emailGrupeArray,
          sharefolders: sharefolderPristupArray,
          phone: sluzbeniMobitelArray,
          car: sluzbeniAutomobilArray,
          visa: sluzbenaVisaKarticaArray,
          dms: dmsArray,
          others: ostaloArray,
          radni_stol: radniStolArray,
          vizitka: vizitkaArray,
          completion: 4,
          //--------------------------------
          ldc: LDCArray,
          mala_lokacija: malaLokacijaArray,
          velika_lokacija: velikaLokacijaArray,
          jadranska_hrvatska: jadranskaHrvatskaArray,
          kontinental_hrvatska: kontinentalnaHrvatskaArray,
          // lean: leanArray,
          upravljanje_kategorijama: upravljanjeKategorijamaArray,
          upravljanje_cijenima: upravljanjeCijenamaArray,
          WMS_tim: WMSArray,
          transport: transportArray,
          upravljanje_zalihom: upravljanjeZalihomArray,
          izvozna_prodaja: izvoznaProdajaArray,
          gospodarski_program: gospodarskiProgramArray,
          // usluge_u_prodaju: uslugeProdajuArray,
          klucni_kupci: kljucniKupciArray,
          online_kanali_prodaje: onlineKanaliProdajeArray,
          poslovna_inteligencija: poslovnaInteligencijaArray,
          ljudksi_resursi: ljudskiResursiArray,
          call_center: callCentarArray,
          DTI: DTIArray,
          informatika: informatikaArray,
          marketing: marketingArray,
          financije: racunovodstvoArray,
          korporativniUpravlje: korporativnoUpravljanjeArray,
          odrzavanje: odrzavanjeInvesticijeArray,
          // odjel_sredisnje_nabave: odjel_sredisnje_nabaveArray,
          // razvoj_poslovanja: razvoj_poslovanjaArray,
          poslovanje_guma: poslovanje_gumaArray,
          ICT_infrastruktura: ICT_infrastrukturaArray,
          kontroling: kontrolingArray,
          // odjel_distribucije: odjel_distribucijeArray,
          maticni_podaci: maticniPodaciArray,
          podrska_u_nabavi: podrskaNabaviArray,
          alati_servisna_oprema: alatiServisnaOpremaArray,
          acc: accArray,
          industrijski_program: industrijskiProgramArray,
          tec: tecArray,
          crm: crmArray,
        })
        .then(() => {
          karticaArray = [];
          racunaloArray = [];
          infProgramiArray = [];
          biArray = [];
          sluzbeniAutomobilArray = [];
          setSluzbeniAutomobilData('');
          setSluzbeniAutomobilData2('');
          sluzbeniMobitelArray = [];
          setSluzbeniMobitelData('');
          sluzbenaVisaKarticaArray = [];
          setSluzbenaVisaKarticaData('');
          ostaloArray = [];
          dmsArray = [];
          emailGrupeArray = [];
          sharefolderPristupArray = [];
          vizitkaArray = [];
          radniStolArray = [];
          //--------------------------------
          LDCArray = [];
          malaLokacijaArray = [];
          velikaLokacijaArray = [];
          kontinentalnaHrvatskaArray = [];
          jadranskaHrvatskaArray = [];
          // leanArray = [];
          upravljanjeKategorijamaArray = [];
          upravljanjeCijenamaArray = [];
          WMSArray = [];
          transportArray = [];
          upravljanjeZalihomArray = [];
          izvoznaProdajaArray = [];
          gospodarskiProgramArray = [];
          // uslugeProdajuArray = [];
          kljucniKupciArray = [];
          onlineKanaliProdajeArray = [];
          poslovnaInteligencijaArray = [];
          ljudskiResursiArray = [];
          callCentarArray = [];
          DTIArray = [];
          informatikaArray = [];
          marketingArray = [];
          racunovodstvoArray = [];
          korporativnoUpravljanjeArray = [];
          odrzavanjeInvesticijeArray = [];
          // odjel_sredisnje_nabaveArray = [];
          // razvoj_poslovanjaArray = [];
          poslovanje_gumaArray = [];
          ICT_infrastrukturaArray = [];
          kontrolingArray = [];
          // odjel_distribucijeArray = [];
          maticniPodaciArray = [];
          podrskaNabaviArray = [];
          alatiServisnaOpremaArray = [];
          accArray = [];
          industrijskiProgramArray = [];
          tecArray = [];
          crmArray = [];

          setDisabledButton(false);
          disableLoading();
          navigate('/dashboard');
        });
    } catch (error) {
      console.log(error);
    }
  };

  // RADIO BUTTONS EVENTS
  const handleChangeKartica = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, kartica: true }));
    if (event.target.value === 'yes') setKartica(true);
    if (event.target.value === 'no') {
      setKartica(false);
      karticaArray = [];
    }
  };
  const handleChangeRacunalo = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, radniStol: true }));
    if (event.target.value === 'yes') setRacunalo(true);
    if (event.target.value === 'no') {
      setRacunalo(false);
      racunaloArray = [];
    }
  };
  const handleChangeInfProgrami = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, racunalo: true }));
    if (event.target.value === 'yes') setInfProgrami(true);
    if (event.target.value === 'no') {
      setInfProgrami(false);
      infProgramiArray = [];
    }
  };
  const handleChangeBi = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, email: true }));
    if (event.target.value === 'yes') {
      setBi(true);
      biArray = [];
    }
    if (event.target.value === 'no') {
      setBi(false);
      setBiOstalo('');
      // setBiZaposlenik('');
      biArray = [];
    }
  };
  const handleChangeEmail = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, infProgrami: true }));
    if (event.target.value === 'yes') setEmail(true);
    if (event.target.value === 'no') {
      setEmail(false);
      setEmailGrupe('');
    }
  };
  const handleChangeSharefolder = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, BI: true }));
    if (event.target.value === 'yes') setSharefolder(true);
    if (event.target.value === 'no') {
      setSharefolder(false);
      setSharefolderPristup('');
    }
  };

  const handleChangeSluzbeniMobitel = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, sharefolder: true }));
    if (event.target.value === 'yes') setSluzbeniMobitel(true);
    if (event.target.value === 'no') {
      setSluzbeniMobitel(false);
      setSluzbeniMobitelData('');
      sluzbeniMobitelArray = [];
    }
  };
  const handleChangeSluzbeniAutomobil = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, DMS: true }));
    if (event.target.value === 'yes') setSluzbeniAutomobil(true);
    if (event.target.value === 'no') {
      setSluzbeniAutomobil(false);
      sluzbeniAutomobilArray = [];
      setSluzbeniAutomobilData('');
      setSluzbeniAutomobilData2('');
    }
  };
  const handleChangeSluzbenaVisaKartica = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, mobitel: true }));
    if (event.target.value === 'yes') setSluzbenaVisaKartica(true);
    if (event.target.value === 'no') {
      setSluzbenaVisaKartica(false);
      setSluzbenaVisaKarticaData('');
    }
  };
  const handleChangeDms = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, visa: true }));
    if (event.target.value === 'yes') setDms(true);
    if (event.target.value === 'no') {
      setDms(false);
      dmsArray = [];
    }
  };
  const handleChangeOstalo = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, vizitka: true }));
    if (event.target.value === 'yes') setOstalo(true);
    if (event.target.value === 'no') {
      setOstalo(false);
      ostaloArray = [];
    }
  };
  const handleChangeRadniStol = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, automobil: true }));
    if (event.target.value === 'yes') setRadniStolData('Radni stol i stolica');
    if (event.target.value === 'no') {
      setRadniStolData('');
      radniStolArray = [];
    }
  };
  const handleChangeVizitka = (event: any) => {
    setIsAllSelected((prevState) => ({ ...prevState, ostalo: true }));
    if (event.target.value === 'yes') setVizitkaData('Vizitka');
    if (event.target.value === 'no') {
      setVizitkaData('');
      vizitkaArray = [];
    }
  };
  //---------------------------------------------------------------------------
  const handleChangeLDC = (event: any) => {
    if (event.target.value === 'yes') {
      setLDC(true);
      LDCArray[0] = upoznavanjeOptions1[0].value;
    }
    if (event.target.value === 'no') {
      setLDC(false);
      setLDCData('');
      LDCArray = [];
    }
  };
  const handleChangeMalaLokacija = (event: any) => {
    if (event.target.value === 'yes') {
      setMalaLokacija(true);
      malaLokacijaArray[0] = upoznavanjeOptions1[0].value;
    }
    if (event.target.value === 'no') {
      setMalaLokacija(false);
      setMalaLokacijaData('');
      malaLokacijaArray = [];
    }
  };
  const handleChangeVelikaLokacija = (event: any) => {
    if (event.target.value === 'yes') {
      setVelikaLokacija(true);
      velikaLokacijaArray[0] = upoznavanjeOptions1[0].value;
    }
    if (event.target.value === 'no') {
      setVelikaLokacija(false);
      setVelikaLokacijaData('');
      velikaLokacijaArray = [];
    }
  };
  const handleChangeKontinentalnaHrvatska = (event: any) => {
    if (event.target.value === 'yes') {
      setKontinentalnaHrvatska(true);
      kontinentalnaHrvatskaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setKontinentalnaHrvatska(false);
      setKontinentalnaHrvatskaData('');
      kontinentalnaHrvatskaArray = [];
    }
  };
  const handleChangeJadranskaHrvatska = (event: any) => {
    if (event.target.value === 'yes') {
      setJadranskaHrvatska(true);
      jadranskaHrvatskaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setJadranskaHrvatska(false);
      setJadranskaHrvatskaData('');
      jadranskaHrvatskaArray = [];
    }
  };
  // const handleChangeLean = (event: any) => {
  //   if (event.target.value === 'yes') {
  //     setLean(true);
  //     leanArray[0] = upoznavanjeOptions2[0].value;
  //   }
  //   if (event.target.value === 'no') {
  //     setLean(false);
  //     setLeanData('');
  //     leanArray = [];
  //   }
  // };
  const handleChangeUpravljanjeKategorijama = (event: any) => {
    if (event.target.value === 'yes') {
      setUpravljanjeKategorijama(true);
      upravljanjeKategorijamaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setUpravljanjeKategorijama(false);
      setUpravljanjeKategorijamaData('');
      upravljanjeKategorijamaArray = [];
    }
  };
  const handleChangeUpravljanjeCijenama = (event: any) => {
    if (event.target.value === 'yes') {
      setUpravljanjeCijenama(true);
      upravljanjeCijenamaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setUpravljanjeCijenama(false);
      setUpravljanjeCijenamaData('');
      upravljanjeCijenamaArray = [];
    }
  };
  const handleChangeWMS = (event: any) => {
    if (event.target.value === 'yes') {
      setWMS(true);
      WMSArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setWMS(false);
      setWMSData('');
      WMSArray = [];
    }
  };
  const handleChangeTransport = (event: any) => {
    if (event.target.value === 'yes') {
      setTransport(true);
      transportArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setTransport(false);
      setTransportData('');
      transportArray = [];
    }
  };
  const handleChangeUpravljanjeZalihom = (event: any) => {
    if (event.target.value === 'yes') {
      setUpravljanjeZalihom(true);
      upravljanjeZalihomArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setUpravljanjeZalihom(false);
      setUpravljanjeZalihomData('');
      upravljanjeZalihomArray = [];
    }
  };
  const handleChangeIzvoznaProdaja = (event: any) => {
    if (event.target.value === 'yes') {
      setIzvoznaProdaja(true);
      izvoznaProdajaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setIzvoznaProdaja(false);
      setIzvoznaProdajaData('');
      izvoznaProdajaArray = [];
    }
  };
  const handleChangeGospodarskiProgram = (event: any) => {
    if (event.target.value === 'yes') {
      setGospodarskiProgram(true);
      gospodarskiProgramArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setGospodarskiProgram(false);
      setGospodarskiProgramData('');
      gospodarskiProgramArray = [];
    }
  };
  // const handleChangeUslugeProdaju = (event: any) => {
  //   if (event.target.value === 'yes') {
  //     setUslugeProdaju(true);
  //     uslugeProdajuArray[0] = upoznavanjeOptions2[0].value;
  //   }
  //   if (event.target.value === 'no') {
  //     setUslugeProdaju(false);
  //     setUslugeProdajuData('');
  //     uslugeProdajuArray = [];
  //   }
  // };
  const handleChangeKljucniKupci = (event: any) => {
    if (event.target.value === 'yes') {
      setKljucniKupci(true);
      kljucniKupciArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setKljucniKupci(false);
      setKljucniKupciData('');
      kljucniKupciArray = [];
    }
  };
  const handleChangeOnlineKanaliProdaje = (event: any) => {
    if (event.target.value === 'yes') {
      setOnlineKanaliProdaje(true);
      onlineKanaliProdajeArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setOnlineKanaliProdaje(false);
      setOnlineKanaliProdajeData('');
      onlineKanaliProdajeArray = [];
    }
  };
  const handleChangePoslovnaInteligencija = (event: any) => {
    if (event.target.value === 'yes') {
      setPoslovnaInteligencija(true);
      poslovnaInteligencijaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setPoslovnaInteligencija(false);
      setPoslovnaInteligencijaData('');
      poslovnaInteligencijaArray = [];
    }
  };
  const handleChangeLjudskiResursi = (event: any) => {
    if (event.target.value === 'yes') {
      setLjudskiResursi(true);
      ljudskiResursiArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setLjudskiResursi(false);
      setLjudskiResursiData('');
      ljudskiResursiArray = [];
    }
  };
  const handleChangeCallCentar = (event: any) => {
    if (event.target.value === 'yes') {
      setCallCentar(true);
      callCentarArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setCallCentar(false);
      setCallCentarData('');
      callCentarArray = [];
    }
  };
  const handleChangeDTI = (event: any) => {
    if (event.target.value === 'yes') {
      setDTI(true);
      DTIArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setDTI(false);
      setDTIData('');
      DTIArray = [];
    }
  };
  const handleChangeInformatika = (event: any) => {
    if (event.target.value === 'yes') {
      setInformatika(true);
      informatikaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setInformatika(false);
      setInformatikaData('');
      informatikaArray = [];
    }
  };
  const handleChangeMarketing = (event: any) => {
    if (event.target.value === 'yes') {
      setMarketing(true);
      marketingArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setMarketing(false);
      setMarketingData('');
      marketingArray = [];
    }
  };
  const handleChangeRacunovodstvo = (event: any) => {
    if (event.target.value === 'yes') {
      setRacunovodstvo(true);
      racunovodstvoArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setRacunovodstvo(false);
      setRacunovodstvoData('');
      racunovodstvoArray = [];
    }
  };
  const handleChangeKorporativnoUpravljanje = (event: any) => {
    if (event.target.value === 'yes') {
      setKorporativnoUpravljanje(true);
      korporativnoUpravljanjeArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setKorporativnoUpravljanje(false);
      setKorporativnoUpravljanjeData('');
      korporativnoUpravljanjeArray = [];
    }
  };
  const handleChangeOdrzavanjeInvesticije = (event: any) => {
    if (event.target.value === 'yes') {
      setOdrzavanjeInvesticije(true);
      odrzavanjeInvesticijeArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setOdrzavanjeInvesticije(false);
      setOdrzavanjeInvesticijeData('');
      odrzavanjeInvesticijeArray = [];
    }
  };
  // const handleChangeOdjel_sredisnje_nabave = (event: any) => {
  //   if (event.target.value === 'yes') {
  //     setOdjel_sredisnje_nabave(true);
  //     odjel_sredisnje_nabaveArray[0] = upoznavanjeOptions2[0].value;
  //   }
  //   if (event.target.value === 'no') {
  //     setOdjel_sredisnje_nabave(false);
  //     setOdjel_sredisnje_nabaveData('');
  //     odjel_sredisnje_nabaveArray = [];
  //   }
  // };
  // const handleChangeRazvoj_poslovanja = (event: any) => {
  //   if (event.target.value === 'yes') {
  //     setRazvoj_poslovanja(true);
  //     razvoj_poslovanjaArray[0] = upoznavanjeOptions2[0].value;
  //   }
  //   if (event.target.value === 'no') {
  //     setRazvoj_poslovanja(false);
  //     setRazvoj_poslovanjaData('');
  //     razvoj_poslovanjaArray = [];
  //   }
  // };
  const handleChangePoslovanje_guma = (event: any) => {
    if (event.target.value === 'yes') {
      setPoslovanje_guma(true);
      poslovanje_gumaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setPoslovanje_guma(false);
      setPoslovanje_gumaData('');
      poslovanje_gumaArray = [];
    }
  };
  const handleChangeICT_infrastruktura = (event: any) => {
    if (event.target.value === 'yes') {
      setICT_infrastruktura(true);
      ICT_infrastrukturaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setICT_infrastruktura(false);
      setICT_infrastrukturaData('');
      ICT_infrastrukturaArray = [];
    }
  };
  const handleChangeKontroling = (event: any) => {
    if (event.target.value === 'yes') {
      setKontroling(true);
      kontrolingArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setKontroling(false);
      setKontrolingData('');
      kontrolingArray = [];
    }
  };
  // const handleChangeOdjel_distribucije = (event: any) => {
  //   if (event.target.value === 'yes') {
  //     setOdjel_distribucije(true);
  //     odjel_distribucijeArray[0] = upoznavanjeOptions2[0].value;
  //   }
  //   if (event.target.value === 'no') {
  //     setOdjel_distribucije(false);
  //     setOdjel_distribucijeData('');
  //     odjel_distribucijeArray = [];
  //   }
  // };
  const handleChangeMaticniPodaci = (event: any) => {
    if (event.target.value === 'yes') {
      setMaticniPodaci(true);
      maticniPodaciArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setMaticniPodaci(false);
      setMaticniPodaciData('');
      maticniPodaciArray = [];
    }
  };
  const handleChangePodrskaNabavi = (event: any) => {
    if (event.target.value === 'yes') {
      setPodrskaNabavi(true);
      podrskaNabaviArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setPodrskaNabavi(false);
      setPodrskaNabaviData('');
      podrskaNabaviArray = [];
    }
  };
  const handleChangeAlatiServisnaOprema = (event: any) => {
    if (event.target.value === 'yes') {
      setAlatiServisnaOprema(true);
      alatiServisnaOpremaArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setAlatiServisnaOprema(false);
      setAlatiServisnaOpremaData('');
      alatiServisnaOpremaArray = [];
    }
  };
  const handleChangeAcc = (event: any) => {
    if (event.target.value === 'yes') {
      setAcc(true);
      accArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setAcc(false);
      setAccData('');
      accArray = [];
    }
  };
  const handleChangeIndustrijskiProgram = (event: any) => {
    if (event.target.value === 'yes') {
      setIndustrijskiProgram(true);
      industrijskiProgramArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setIndustrijskiProgram(false);
      setIndustrijskiProgramData('');
      industrijskiProgramArray = [];
    }
  };
  const handleChangeTec = (event: any) => {
    if (event.target.value === 'yes') {
      setTec(true);
      tecArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setTec(false);
      setTecData('');
      tecArray = [];
    }
  };
  const handleChangeCrm = (event: any) => {
    if (event.target.value === 'yes') {
      setCrm(true);
      crmArray[0] = upoznavanjeOptions2[0].value;
    }
    if (event.target.value === 'no') {
      setCrm(false);
      setCrmData('');
      crmArray = [];
    }
  };

  //CHECKBOX EVENTS
  const handleChangeKarticaOptions = (event: any) => {
    if (event.target.checked) karticaArray.push(event.target.value);
    else karticaArray = karticaArray.filter((e) => e !== event.target.value);
  };
  const handleChangeRacunaloOptions = (event: any) => {
    if (event.target.checked) racunaloArray.push(event.target.value);
    else racunaloArray = racunaloArray.filter((e) => e !== event.target.value);
  };
  const handleChangeInfProgramiOptions = (event: any) => {
    if (event.target.checked) infProgramiArray.push(event.target.value);
    else infProgramiArray = infProgramiArray.filter((e) => e !== event.target.value);
  };
  const handleChangeBiOptions = (event: any) => {
    biArray = [];
    event.target.value !== '---Odaberi---' && biArray.push(event.target.value);
  };
  const handleChangeSluzbeniMobitelOptions = (event: any) => {
    setSluzbeniMobitelData(event.target.value);
  };
  const handleChangeSluzbeniMobitelOptionsCheck = (event: any) => {
    if (event.target.checked) sluzbeniMobitelCheckArray.push(event.target.value);
    else sluzbeniMobitelCheckArray = sluzbeniMobitelCheckArray.filter((e) => e !== event.target.value);
  };
  const handleChangeSluzbeniAutomobilOptions = (event: any) => {
    setSluzbeniAutomobilData(event.target.value);
  };
  const handleChangeSluzbeniAutomobilOptions2 = (event: any) => {
    setSluzbeniAutomobilData2(event.target.value);
  };
  const handleChangeSluzbeniAutomobilOptionsCheck = (event: any) => {
    if (event.target.checked) sluzbeniAutomobilCheckArray.push(event.target.value);
    else sluzbeniAutomobilCheckArray = sluzbeniAutomobilCheckArray.filter((e) => e !== event.target.value);
  };
  const handleChangeSluzbenaVisaKarticaOptions = (event: any) => {
    setSluzbenaVisaKarticaData(event.target.value);
  };
  // const handleChangeOstaloOptions = (event: any) => {
  //   if (event.target.checked) ostaloArray.push(event.target.value);
  //   else ostaloArray = ostaloArray.filter((e) => e !== event.target.value);
  //
  // };
  const handleChangeDmsOptions = (event: any) => {
    if (event.target.checked) dmsArray.push(event.target.value);
    else dmsArray = dmsArray.filter((e) => e !== event.target.value);
  };
  //---------------------------------------------------------------------------
  const handleChangeLDCOptions = (event: any) => {
    LDCArray = [];
    LDCArray.push(event.target.value);
  };
  const handleChangeMalaLokacijaOptions = (event: any) => {
    malaLokacijaArray = [];
    malaLokacijaArray.push(event.target.value);
  };
  const handleChangeVelikaLokacijaOptions = (event: any) => {
    velikaLokacijaArray = [];
    velikaLokacijaArray.push(event.target.value);
  };
  const handleChangeKontinentalnaHrvatskaOptions = (event: any) => {
    kontinentalnaHrvatskaArray = [];
    kontinentalnaHrvatskaArray.push(event.target.value);
  };
  const handleChangeJadranskaHrvatskaOptions = (event: any) => {
    jadranskaHrvatskaArray = [];
    jadranskaHrvatskaArray.push(event.target.value);
  };
  // const handleChangeLeanOptions = (event: any) => {
  //   leanArray = [];
  //   leanArray.push(event.target.value);
  // };
  const handleChangeUpravljanjeKategorijamaOptions = (event: any) => {
    upravljanjeKategorijamaArray = [];
    upravljanjeKategorijamaArray.push(event.target.value);
  };
  const handleChangeUpravljanjeCijenamaOptions = (event: any) => {
    upravljanjeCijenamaArray = [];
    upravljanjeCijenamaArray.push(event.target.value);
  };
  const handleChangeWMSOptions = (event: any) => {
    WMSArray = [];
    WMSArray.push(event.target.value);
  };
  const handleChangeTransportOptions = (event: any) => {
    transportArray = [];
    transportArray.push(event.target.value);
  };
  const handleChangeUpravljanjeZalihomOptions = (event: any) => {
    upravljanjeZalihomArray = [];
    upravljanjeZalihomArray.push(event.target.value);
  };
  const handleChangeIzvoznaProdajaOptions = (event: any) => {
    izvoznaProdajaArray = [];
    izvoznaProdajaArray.push(event.target.value);
  };
  const handleChangeGospodarskiProgramOptions = (event: any) => {
    gospodarskiProgramArray = [];
    gospodarskiProgramArray.push(event.target.value);
  };
  // const handleChangeUslugeProdajuOptions = (event: any) => {
  //   uslugeProdajuArray = [];
  //   uslugeProdajuArray.push(event.target.value);
  // };
  const handleChangeKljucniKupciOptions = (event: any) => {
    kljucniKupciArray = [];
    kljucniKupciArray.push(event.target.value);
  };
  const handleChangeOnlineKanaliProdajeOptions = (event: any) => {
    onlineKanaliProdajeArray = [];
    onlineKanaliProdajeArray.push(event.target.value);
  };
  const handleChangePoslovnaInteligencijaOptions = (event: any) => {
    poslovnaInteligencijaArray = [];
    poslovnaInteligencijaArray.push(event.target.value);
  };
  const handleChangeLjudskiResursiOptions = (event: any) => {
    ljudskiResursiArray = [];
    ljudskiResursiArray.push(event.target.value);
  };
  const handleChangeCallCentarOptions = (event: any) => {
    callCentarArray = [];
    callCentarArray.push(event.target.value);
  };
  const handleChangeDTIOptions = (event: any) => {
    DTIArray = [];
    DTIArray.push(event.target.value);
  };
  const handleChangeInformatikaOptions = (event: any) => {
    informatikaArray = [];
    informatikaArray.push(event.target.value);
  };
  const handleChangeMarketingOptions = (event: any) => {
    marketingArray = [];
    marketingArray.push(event.target.value);
  };
  const handleChangeRacunovodstvoOptions = (event: any) => {
    racunovodstvoArray = [];
    racunovodstvoArray.push(event.target.value);
  };
  const handleChangeKorporativnoUpravljanjeOptions = (event: any) => {
    korporativnoUpravljanjeArray = [];
    korporativnoUpravljanjeArray.push(event.target.value);
  };
  const handleChangeOdrzavanjeInvesticijeOptions = (event: any) => {
    odrzavanjeInvesticijeArray = [];
    odrzavanjeInvesticijeArray.push(event.target.value);
  };
  // const handleChangeOdjel_sredisnje_nabaveOptions = (event: any) => {
  //   odjel_sredisnje_nabaveArray = [];
  //   odjel_sredisnje_nabaveArray.push(event.target.value);
  // };
  // const handleChangeRazvoj_poslovanjaOptions = (event: any) => {
  //   razvoj_poslovanjaArray = [];
  //   razvoj_poslovanjaArray.push(event.target.value);
  // };
  const handleChangePoslovanje_gumaOptions = (event: any) => {
    poslovanje_gumaArray = [];
    poslovanje_gumaArray.push(event.target.value);
  };
  const handleChangeICT_infrastrukturaOptions = (event: any) => {
    ICT_infrastrukturaArray = [];
    ICT_infrastrukturaArray.push(event.target.value);
  };
  const handleChangeKontrolingOptions = (event: any) => {
    kontrolingArray = [];
    kontrolingArray.push(event.target.value);
  };
  // const handleChangeOdjel_distribucijeOptions = (event: any) => {
  //   odjel_distribucijeArray = [];
  //   odjel_distribucijeArray.push(event.target.value);
  // };
  const handleChangeMaticniPodaciOptions = (event: any) => {
    maticniPodaciArray = [];
    maticniPodaciArray.push(event.target.value);
  };
  const handleChangePodrskaNabaviOptions = (event: any) => {
    podrskaNabaviArray = [];
    podrskaNabaviArray.push(event.target.value);
  };
  const handleChangeAlatiServisnaOpremaOptions = (event: any) => {
    alatiServisnaOpremaArray = [];
    alatiServisnaOpremaArray.push(event.target.value);
  };
  const handleChangeAccOptions = (event: any) => {
    accArray = [];
    accArray.push(event.target.value);
  };
  const handleChangeIndustrijskiProgramOptions = (event: any) => {
    industrijskiProgramArray = [];
    industrijskiProgramArray.push(event.target.value);
  };
  const handleChangeTecOptions = (event: any) => {
    tecArray = [];
    tecArray.push(event.target.value);
  };
  const handleChangeCrmOptions = (event: any) => {
    crmArray = [];
    crmArray.push(event.target.value);
  };

  return (
    <div className='relative-element'>
      <form onSubmit={handleSubmit} className='add-employee-box'>
        <img src={arrowLeft} className='button-back' alt='' onClick={back} />
        {loading ? (
          <div className='loading'>
            <ClimbingBoxLoader color={'#4f46e5'} loading={loading} size={30} />
          </div>
        ) : (
          <></>
        )}
        <h2>Novi zaposlenik</h2>
        <article className={`${stranica ? 'hide' : ''} add-employee-box__article center-column`}>
          <p className='description-text'>
            <span className='margin-1'>
              Organiziranje cjelovitog radnog prostora i alata (radne opreme, programi, pristupi i ostalih radnih uvjeta) za novog kolegu/icu je
              potrebno kako bi bio/bila u potpunosti operativan/a od prvog radnog dana.
            </span>
            <br />
            <br />
            <span>
              U skladu s tim, molimo vas da označite sve što je potrebno za novog kolega/icu sukladno potrebama i opisu radnog mjesta kako bi zaduženi
              odjeli mogli na vrijeme pripremiti potrebno.
            </span>
            <br />
            <br />
            <span>Hvala unaprijed na suradnji.</span>
          </p>
          <h4>Podaci o zaposleniku</h4>
          <div className='add-employee-box__article__columns margin-bottom-2'>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='employeeName'>Ime i prezime: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setEmployeename(e.target.value);
                }}
              />
            </div>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='employeePosition'>Radno mjesto: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setEmployeeposition(e.target.value);
                }}
              />
            </div>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='employeePosition'>Odjel: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setEmployeedepartment(e.target.value);
                }}
              />
            </div>
            <div className='filters__date margin-bottom-1'>
              {/* <img className='filters__date__icon' onClick={() => setActiveCalendar(!activeCalendar)} src={calendarIcon} alt='' /> */}
              <label className='center-column margin-bottom-1 margin-top-1' htmlFor='employeePosition'>
                Datum početka rada:{' '}
              </label>
              <div className={`filters__date__calendar`}>
                <Calendar onChange={(e) => setDateofemployment(e)} value={dateofemployment} />
              </div>
            </div>

            {/* <div className='add-employee-box__article__columns__item'>
            <label htmlFor='employeePosition'>Datum zaposlenja: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                // onChange={(e) => {
                //   setDateofemployment(e.target.value);
                // }}
              />
            </div> */}
          </div>
        </article>
        <article className={`${stranica ? 'hide' : ''} add-employee-box__article center-column card-buddy`}>
          <div className='add-employee-box__article__buddy-info'>
            <h4>Podaci o buddy-u</h4>
            <Tippy content='Osoba od povjerenja i pomoći u orijentaciji u prvim mjesecima rada novog kolege. Za razliku od mentora (prenosi radne zadatke i upoznaje s radnom ulogom), Buddy prenosi sva nestrukturirana znanja o temama kao što su – kultura na radnom mjestu, politike kompanije, povlastice, benefiti i slično.'>
              <img className='add-employee-box__article__buddy-info__icon' src={iconInfo} alt='' />
            </Tippy>
          </div>
          <div className='add-employee-box__article__columns margin-bottom-2'>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='buddyName'>Ime i prezime: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setBuddy(e.target.value);
                }}
              />
            </div>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='buddyPosition'>Odjel: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setBuddyposition(e.target.value);
                }}
              />
            </div>
            <div className='add-employee-box__article__columns__item'>
              <label htmlFor='buddyPosition'>Email: </label>
              <input
                required
                // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity('Molim popunite obvezan podatak')}
                // onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                className='add-employee-box__article__columns__item__input'
                type='text'
                onChange={(e) => {
                  setBuddyemail(e.target.value);
                }}
              />
            </div>
          </div>
        </article>

        <section className={`${stranica ? 'hide' : ''} add-employee-box__article-columns`}>
          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Kartica za ulazak</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeKartica}>
                <input name='kartica-za-ulazak' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='kartica-za-ulazak' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {kartica ? (
                  karticaOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input className='margin-top-1' type='checkbox' value={karticaOptions[index]} onChange={handleChangeKarticaOptions} />
                        <label htmlFor={karticaOptions[index]}>{karticaOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Radni stol i stolica</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeRadniStol}>
                <input name='radniStol' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='radniStol' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Računalo</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeRacunalo}>
                <input name='racunalo' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='racunalo' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {racunalo ? (
                  racunaloOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input className='margin-top-1' type='checkbox' value={racunaloOptions[index]} onChange={handleChangeRacunaloOptions} />
                        <label htmlFor={racunaloOptions[index]}>{racunaloOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
                <div className='add-employee-box__article__columns__item margin-top-2'>
                  {racunalo ? (
                    <React.Fragment>
                      <label htmlFor='racunaloOstalo'>Ostalo: </label>
                      <input
                        className='add-employee-box__article__columns__item__input'
                        type='text'
                        onChange={(e) => {
                          setRacunaloOstalo(e.target.value);
                        }}
                      />
                      <p className='add-employee-box__article__columns__item_message'>
                        Ukoliko unosite više ostalih stavki, podijelite ih znakom ";"
                      </p>
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>E-mail adresa</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeEmail}>
                <input name='email' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='email' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {email ? (
                  <React.Fragment>
                    <label htmlFor='emailGrupe'>Grupe email adresa: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setEmailGrupe(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko unosite više grupa email adresa, podijelite ih znakom ";"
                    </p>
                  </React.Fragment>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Informatički programi</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeInfProgrami}>
                <input name='infProgrami' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='infProgrami' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {infProgrami ? (
                  infProgramiOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input className='margin-top-1' type='checkbox' value={infProgramiOptions[index]} onChange={handleChangeInfProgramiOptions} />
                        <label htmlFor={infProgramiOptions[index]}>{infProgramiOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
                <div className='add-employee-box__article__columns__item margin-top-2'>
                  {infProgrami ? (
                    <React.Fragment>
                      <label htmlFor='infProgramiNavision'>Navision (rola): </label>
                      <input
                        className='margin-bottom-2 add-employee-box__article__columns__item__input'
                        type='text'
                        onChange={(e) => {
                          setInfProgramiNavision(e.target.value);
                        }}
                      />
                      <label htmlFor='infProgramiOstalo'>Ostalo: </label>
                      <input
                        className='add-employee-box__article__columns__item__input'
                        type='text'
                        onChange={(e) => {
                          setInfProgramiOstalo(e.target.value);
                        }}
                      />
                      <p className='add-employee-box__article__columns__item_message'>
                        Ukoliko unosite više ostalih stavki, podijelite ih znakom ";"
                      </p>
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>BI (Tabularna kocka)</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeBi}>
                <input name='bi' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='bi' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {bi ? (
                  <select className='dropdown' onChange={handleChangeBiOptions} name='' id=''>
                    {biOptions.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div />
                )}
                <div className='add-employee-box__article__columns__item margin-top-2'>
                  {bi ? (
                    <React.Fragment>
                      <label htmlFor='biOstalo'>Ostalo: </label>
                      <input
                        className='add-employee-box__article__columns__item__input'
                        type='text'
                        placeholder='nadopisati...'
                        onChange={(e) => {
                          setBiOstalo(e.target.value);
                        }}
                      />
                      <p className='add-employee-box__article__columns__item_message'>
                        Ukoliko unosite više ostalih stavki, podijelite ih znakom ";"
                      </p>
                      {/* <label htmlFor='biPrava'>Dodjeliti prava kakva ima i zaposlenik: </label>
                      <input
                        className='add-employee-box__article__columns__item__input'
                        type='text'
                        placeholder='ime i prezime...'
                        onChange={(e) => {
                          setBiZaposlenik(e.target.value);
                        }}
                      /> */}
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Pristup sharefolderima</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeSharefolder}>
                <input name='sharefolder' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='sharefolder' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {sharefolder ? (
                  <React.Fragment>
                    <label htmlFor='sharefolderPristup'>Sharefolderi: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setSharefolderPristup(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>Ukoliko unosite više sharefoldera, podijelite ih znakom ";"</p>
                  </React.Fragment>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>DMS prava</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeDms}>
                <input name='dms' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='dms' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {dms ? (
                  dmsOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input className='margin-top-1' type='checkbox' value={dmsOptions[index]} onChange={handleChangeDmsOptions} />
                        <label htmlFor={dmsOptions[index]}>{dmsOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Službeni mobitel</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeSluzbeniMobitel}>
                <input name='sluzbeniMobitel' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='sluzbeniMobitel' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {sluzbeniMobitel ? (
                  sluzbeniMobitelOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='radio'
                          name='phone'
                          value={sluzbeniMobitelOptions[index]}
                          onChange={handleChangeSluzbeniMobitelOptions}
                        />
                        <label htmlFor={sluzbeniMobitelOptions[index]}>{sluzbeniMobitelOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
              <div className='full-width'>
                {sluzbeniMobitel ? (
                  sluzbeniMobitelOptionsCheck.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='checkbox'
                          name='phoneCatch'
                          value={sluzbeniMobitelOptionsCheck[index]}
                          onChange={handleChangeSluzbeniMobitelOptionsCheck}
                        />
                        <label htmlFor={sluzbeniMobitelOptionsCheck[index]}>{sluzbeniMobitelOptionsCheck[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
              <div className='add-employee-box__article__columns__item margin-top-2'>
                {sluzbeniMobitel ? (
                  <React.Fragment>
                    <label htmlFor='sluzbeniMobitelOstalo'>Ostalo: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setSluzbeniMobitelOstalo(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>Ukoliko unosite više ostalih stavki, podijelite ih znakom ";"</p>
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Službena Visa kartica</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeSluzbenaVisaKartica}>
                <input name='visaKartica' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='visaKartica' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {sluzbenaVisaKartica ? (
                  sluzbenaVisaKarticaOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='radio'
                          name='visa'
                          value={sluzbenaVisaKarticaOptions[index]}
                          onChange={handleChangeSluzbenaVisaKarticaOptions}
                        />
                        <label htmlFor={sluzbenaVisaKarticaOptions[index]}>{sluzbenaVisaKarticaOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Vizitka</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeVizitka}>
                <input name='vizitka' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='vizitka' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Službeni automobil</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeSluzbeniAutomobil}>
                <input name='sluzbeniAutomobil' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='sluzbeniAutomobil' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div>
                {sluzbeniAutomobil ? (
                  <>
                    {sluzbeniAutomobilOptions.map((opt, index) => {
                      return (
                        <React.Fragment key={index}>
                          <input
                            className='margin-top-1'
                            type='radio'
                            name='car'
                            value={sluzbeniAutomobilOptions[index]}
                            onChange={handleChangeSluzbeniAutomobilOptions}
                          />
                          <label htmlFor={sluzbeniAutomobilOptions[index]}>{sluzbeniAutomobilOptions[index]}</label>
                          <br />
                        </React.Fragment>
                      );
                    })}
                    <p className='margin-bottom-0'>Planirana količina prijeđenih kilometara na godišnjoj razini:</p>
                    <div className='margin-left-1 margin-bottom-1'>
                      {sluzbeniAutomobilOptions2.map((opt, index) => {
                        return (
                          <React.Fragment key={index}>
                            <input
                              className='margin-top-1'
                              type='radio'
                              name='car2'
                              value={sluzbeniAutomobilOptions2[index]}
                              onChange={handleChangeSluzbeniAutomobilOptions2}
                            />
                            <label htmlFor={sluzbeniAutomobilOptions2[index]}>{sluzbeniAutomobilOptions2[index]}</label>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className='full-width'>
                      {sluzbeniAutomobilOptionsCheck.map((opt, index) => {
                        return (
                          <React.Fragment key={index}>
                            <input
                              className='margin-top-1'
                              type='checkbox'
                              value={sluzbeniAutomobilOptionsCheck[index]}
                              onChange={handleChangeSluzbeniAutomobilOptionsCheck}
                            />
                            <label htmlFor={sluzbeniAutomobilOptionsCheck[index]}>{sluzbeniAutomobilOptionsCheck[index]}</label>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div />
                )}
                {/* {sluzbeniAutomobil ? (
                  sluzbeniAutomobilOptions.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='radio'
                          name='car'
                          value={sluzbeniAutomobilOptions[index]}
                          onChange={handleChangeSluzbeniAutomobilOptions}
                        />
                        <label htmlFor={sluzbeniAutomobilOptions[index]}>{sluzbeniAutomobilOptions[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
              <div>
                {sluzbeniAutomobil && <p className='margin-bottom-0'>Planirana količina prijeđenih kilometara na godišnjoj razini:</p>}
                {sluzbeniAutomobil ? (
                  sluzbeniAutomobilOptions2.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='radio'
                          name='car2'
                          value={sluzbeniAutomobilOptions2[index]}
                          onChange={handleChangeSluzbeniAutomobilOptions2}
                        />
                        <label htmlFor={sluzbeniAutomobilOptions2[index]}>{sluzbeniAutomobilOptions2[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
              <div className='full-width'>
                {sluzbeniAutomobil ? (
                  sluzbeniAutomobilOptionsCheck.map((opt, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className='margin-top-1'
                          type='checkbox'
                          value={sluzbeniAutomobilOptionsCheck[index]}
                          onChange={handleChangeSluzbeniAutomobilOptionsCheck}
                        />
                        <label htmlFor={sluzbeniAutomobilOptionsCheck[index]}>{sluzbeniAutomobilOptionsCheck[index]}</label>
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div />
                )} */}
              </div>
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Ostalo</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeOstalo}>
                <input name='ostalo' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='ostalo' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              <div className='add-employee-box__article__columns__item margin-top-2'>
                {ostalo ? (
                  <React.Fragment>
                    <label htmlFor='ostaloOstalo'>Dodatno: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setOstaloOstalo(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>Ukoliko unosite više dodatnih stavki, podijelite ih znakom ";"</p>
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </article>
        </section>

        {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
        {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
        {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}

        <section className={`${stranica ? '' : 'hide'} add-employee-box__article-columns`}>
          <p className='description-text'>
            <span>
              Kako bi novi kolega/ica bio/bila što kvalitetnije uveden u organizaciju i što uspješnije mogao/la preuzeti svoje radne zadatke, u sklopu
              Onboardinga za njega/nju će se organizirati obilazak odjela s naglaskom na onim odjelima koji će biti najviše povezani s
              njegovim/njezinim zaduženjima.
            </span>
            <br />
            <br />
            <span>
              Molimo vas da označite koji odjeli su novom kolegu/ici potrebni za upoznati ih, u kojem vremensku periodu te da navedete projekte,
              programe ili procese na koje je potrebno staviti dodatni naglasak.
            </span>
            <br />
            <br />
            <span>
              Cilj obilaska je upoznati novog kolegu/icu sa svime što mu/joj je potrebno kako bi što ranije i što optimalnije bio uveden/a u svoje
              radno mjesto.
            </span>
            <br />
            <br />
            <span>Hvala unaprijed na suradnji.</span>
          </p>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>LDC</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Skladište</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeLDC}>
                <input name='ldc' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='ldc' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {LDC ? (
                <div>
                  <select className='dropdown' onChange={handleChangeLDCOptions} name='' id=''>
                    {upoznavanjeOptions1.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='LDCData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setLDCData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>POSLOVNICA</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Velika poslovnica</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeVelikaLokacija}>
                <input name='velikaLokacija' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='velikaLokacija' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {velikaLokacija ? (
                <div>
                  <label htmlFor='malaLokacijaData'>Ime poslovnice: </label>
                  <input
                    className='add-employee-box__article__columns__item__input'
                    type='text'
                    onChange={(e) => {
                      setVelikaLokacijaDataPoslovnica(e.target.value);
                    }}
                  />
                  <select className='dropdown margin-top-2' onChange={handleChangeVelikaLokacijaOptions} name='' id=''>
                    {upoznavanjeOptions1.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='velikaLokacijaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setVelikaLokacijaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Mala poslovnica</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeMalaLokacija}>
                <input name='malaLokacija' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='malaLokacija' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {malaLokacija ? (
                <div>
                  <label htmlFor='malaLokacijaData'>Ime poslovnice: </label>
                  <input
                    className='add-employee-box__article__columns__item__input'
                    type='text'
                    onChange={(e) => {
                      setMalaLokacijaDataPoslovnica(e.target.value);
                    }}
                  />
                  <select className='dropdown margin-top-2' onChange={handleChangeMalaLokacijaOptions} name='' id=''>
                    {upoznavanjeOptions1.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='malaLokacijaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setMalaLokacijaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>PRODAJA</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Kontinentalna Hrvatska</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeKontinentalnaHrvatska}>
                <input name='kontinentalnaHrvatska' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='kontinentalnaHrvatska' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {kontinentalnaHrvatska ? (
                <div>
                  <select className='dropdown' onChange={handleChangeKontinentalnaHrvatskaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='kontinentalnaHrvatskaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setKontinentalnaHrvatskaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Jadranska Hrvatska</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeJadranskaHrvatska}>
                <input name='jadranskaHrvatska' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='jadranskaHrvatska' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {jadranskaHrvatska ? (
                <div>
                  <select className='dropdown' onChange={handleChangeJadranskaHrvatskaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='jadranskaHrvatskaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setJadranskaHrvatskaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Online kanali prodaje</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeOnlineKanaliProdaje}>
                <input name='onlineKanaliProdaje' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='onlineKanaliProdaje' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {onlineKanaliProdaje ? (
                <div>
                  <select className='dropdown' onChange={handleChangeOnlineKanaliProdajeOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='onlineKanaliProdajeData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setOnlineKanaliProdajeData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Call centar</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeCallCentar}>
                <input name='callCentar' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='callCentar' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {callCentar ? (
                <div>
                  <select className='dropdown' onChange={handleChangeCallCentarOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='callCentarData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setCallCentarData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>NABAVA</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Upravljanje kategorijama</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeUpravljanjeKategorijama}>
                <input name='upravljanjeKategorijama' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='upravljanjeKategorijama' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {upravljanjeKategorijama ? (
                <div>
                  <select className='dropdown' onChange={handleChangeUpravljanjeKategorijamaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='upravljanjeKategorijamaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setUpravljanjeKategorijamaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Upravljanje cijenama</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeUpravljanjeCijenama}>
                <input name='upravljanjeCijenama' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='upravljanjeCijenama' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {upravljanjeCijenama ? (
                <div>
                  <select className='dropdown' onChange={handleChangeUpravljanjeCijenamaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='upravljanjeCijenamaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setUpravljanjeCijenamaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Matični podaci</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeMaticniPodaci}>
                <input name='maticniPodaci' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='maticniPodaci' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {maticniPodaci ? (
                <div>
                  <select className='dropdown' onChange={handleChangeMaticniPodaciOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='maticniPodaciData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setMaticniPodaciData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Podrška u nabavi</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangePodrskaNabavi}>
                <input name='podrskaNabavi' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='podrskaNabavi' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {podrskaNabavi ? (
                <div>
                  <select className='dropdown' onChange={handleChangePodrskaNabaviOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='podrskaNabaviData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setPodrskaNabaviData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          {/* <article className='add-employee-box__article__columns__category center-column'>
            <h4>Lean</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeLean}>
                <input name='lean' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='lean' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {lean ? (
                <div>
                  <select className='dropdown' onChange={handleChangeLeanOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='leanData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setLeanData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article> */}

          <div className='add-employee-box__article__columns__category-title'>
            <h3>LANAC OPSKRBE</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Upravljanje zalihom</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeUpravljanjeZalihom}>
                <input name='upravljanjeZalihom' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='upravljanjeZalihom' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {upravljanjeZalihom ? (
                <div>
                  <select className='dropdown' onChange={handleChangeUpravljanjeZalihomOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='upravljanjeZalihomData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setUpravljanjeZalihomData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Transport</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeTransport}>
                <input name='transport' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='transport' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {transport ? (
                <div>
                  <select className='dropdown' onChange={handleChangeTransportOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='transportData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setTransportData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>WMS</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeWMS}>
                <input name='WMS' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='WMS' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {WMS ? (
                <div>
                  <select className='dropdown' onChange={handleChangeWMSOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='WMSData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setWMSData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>SPECIJALNI KANALI PRODAJE</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4 className='text-center'>Gospodarski program (nabava i prodaja)</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeGospodarskiProgram}>
                <input name='gospodarskiProgram' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='gospodarskiProgram' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {gospodarskiProgram ? (
                <div>
                  <select className='dropdown' onChange={handleChangeGospodarskiProgramOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='gospodarskiProgramData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setGospodarskiProgramData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Alati i servisna oprema</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeAlatiServisnaOprema}>
                <input name='alatiServisnaOprema' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='alatiServisnaOprema' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {alatiServisnaOprema ? (
                <div>
                  <select className='dropdown' onChange={handleChangeAlatiServisnaOpremaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='alatiServisnaOpremaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setAlatiServisnaOpremaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>ACC servisi</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeAcc}>
                <input name='acc' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='acc' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {acc ? (
                <div>
                  <select className='dropdown' onChange={handleChangeAccOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='accData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setAccData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Industrijski program</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeIndustrijskiProgram}>
                <input name='industrijskiProgram' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='industrijskiProgram' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {industrijskiProgram ? (
                <div>
                  <select className='dropdown' onChange={handleChangeIndustrijskiProgramOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='industrijskiProgramData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setIndustrijskiProgramData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4 className='text-center'>TEC (servis i kontrola kvalitete)</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeTec}>
                <input name='tec' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='tec' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {tec ? (
                <div>
                  <select className='dropdown' onChange={handleChangeTecOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='tecData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setTecData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Ključni kupci</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeKljucniKupci}>
                <input name='kljucniKupci' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='kljucniKupci' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {kljucniKupci ? (
                <div>
                  <select className='dropdown' onChange={handleChangeKljucniKupciOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='kljucniKupciData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setKljucniKupciData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>IZVOZ</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Izvozna prodaja</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeIzvoznaProdaja}>
                <input name='izvoznaProdaja' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='izvoznaProdaja' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {izvoznaProdaja ? (
                <div>
                  <select className='dropdown' onChange={handleChangeIzvoznaProdajaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='izvoznaProdajaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setIzvoznaProdajaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          {/* <article className='add-employee-box__article__columns__category center-column'>
            <h4>Usluge u prodaji</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeUslugeProdaju}>
                <input name='uslugeProdaju' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='uslugeProdaju' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {uslugeProdaju ? (
                <div>
                  <select className='dropdown' onChange={handleChangeUslugeProdajuOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='uslugeProdajuData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setUslugeProdajuData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article> */}

          <div className='add-employee-box__article__columns__category-title'>
            <h3>POSLOVANJE GUMA</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Poslovanje guma</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangePoslovanje_guma}>
                <input name='poslovanje_guma' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='poslovanje_guma' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {poslovanje_guma ? (
                <div>
                  <select className='dropdown' onChange={handleChangePoslovanje_gumaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='poslovanje_gumaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setPoslovanje_gumaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>RAZVOJ POSLOVANJA</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Marketing</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeMarketing}>
                <input name='marketing' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='marketing' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {marketing ? (
                <div>
                  <select className='dropdown' onChange={handleChangeMarketingOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='marketingData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setMarketingData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>CRM</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeCrm}>
                <input name='crm' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='crm' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {crm ? (
                <div>
                  <select className='dropdown' onChange={handleChangeCrmOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='crmData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setCrmData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>KORPORATIVNO UPRAVLJANJE</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Korporativno upravljanje</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeKorporativnoUpravljanje}>
                <input name='korporativnoUpravljanje' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='korporativnoUpravljanje' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {korporativnoUpravljanje ? (
                <div>
                  <select className='dropdown' onChange={handleChangeKorporativnoUpravljanjeOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='korporativnoUpravljanjeData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setKorporativnoUpravljanjeData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>LJUDSKI RESURSI</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Ljudski resursi</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeLjudskiResursi}>
                <input name='ljudskiResursi' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='ljudskiResursi' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {ljudskiResursi ? (
                <div>
                  <select className='dropdown' onChange={handleChangeLjudskiResursiOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='ljudskiResursiData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setLjudskiResursiData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>TEHNOLOGIJE</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Informatika</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeInformatika}>
                <input name='informatika' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='informatika' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {informatika ? (
                <div>
                  <select className='dropdown' onChange={handleChangeInformatikaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='informatikaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setInformatikaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>ICT infrastruktura</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeICT_infrastruktura}>
                <input name='ICT_infrastruktura' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='ICT_infrastruktura' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {ICT_infrastruktura ? (
                <div>
                  <select className='dropdown' onChange={handleChangeICT_infrastrukturaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='ICT_infrastrukturaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setICT_infrastrukturaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>DTI</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeDTI}>
                <input name='DTI' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='DTI' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {DTI ? (
                <div>
                  <select className='dropdown' onChange={handleChangeDTIOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='DTIData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setDTIData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Poslovna inteligencija</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangePoslovnaInteligencija}>
                <input name='poslovnaInteligencija' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='poslovnaInteligencija' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {poslovnaInteligencija ? (
                <div>
                  <select className='dropdown' onChange={handleChangePoslovnaInteligencijaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='poslovnaInteligencijaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setPoslovnaInteligencijaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>ODRŽAVANJE I INVESTICIJE</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Odrzavanje i investicije</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeOdrzavanjeInvesticije}>
                <input name='odrzavanjeInvesticije' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='odrzavanjeInvesticije' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {odrzavanjeInvesticije ? (
                <div>
                  <select className='dropdown' onChange={handleChangeOdrzavanjeInvesticijeOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='odrzavanjeInvesticijeData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setOdrzavanjeInvesticijeData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          <div className='add-employee-box__article__columns__category-title'>
            <h3>RAČUNOVODSTVO</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Računovodstvo</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeRacunovodstvo}>
                <input name='racunovodstvo' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='racunovodstvo' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {racunovodstvo ? (
                <div>
                  <select className='dropdown' onChange={handleChangeRacunovodstvoOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='racunovodstvoData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setRacunovodstvoData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>

          {/* <article className='add-employee-box__article__columns__category center-column'>
            <h4>Odjel središnje nabave</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeOdjel_sredisnje_nabave}>
                <input name='odjel_sredisnje_nabave' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='odjel_sredisnje_nabave' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {odjel_sredisnje_nabave ? (
                <div>
                  <select className='dropdown' onChange={handleChangeOdjel_sredisnje_nabaveOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='odjel_sredisnje_nabaveData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setOdjel_sredisnje_nabaveData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article> */}
          {/* <article className='add-employee-box__article__columns__category center-column'>
            <h4>Razvoj poslovanja</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeRazvoj_poslovanja}>
                <input name='razvoj_poslovanja' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='razvoj_poslovanja' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {razvoj_poslovanja ? (
                <div>
                  <select className='dropdown' onChange={handleChangeRazvoj_poslovanjaOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='razvoj_poslovanjaData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setRazvoj_poslovanjaData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article> */}

          <div className='add-employee-box__article__columns__category-title'>
            <h3>KONTROLING</h3>
          </div>

          <article className='add-employee-box__article__columns__category center-column'>
            <h4>Kontroling</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeKontroling}>
                <input name='kontroling' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='kontroling' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {kontroling ? (
                <div>
                  <select className='dropdown' onChange={handleChangeKontrolingOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='kontrolingData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setKontrolingData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article>
          {/* <article className='add-employee-box__article__columns__category center-column'>
            <h4>Odjel distribucije</h4>
            <div className='center-column'>
              <div className='margin-bottom-1' onChange={handleChangeOdjel_distribucije}>
                <input name='odjel_distribucije' type='radio' value={'yes'} required />
                <label htmlFor='yes'>Da</label>
                <input name='odjel_distribucije' type='radio' value={'no'} />
                <label htmlFor='no'>Ne</label>
              </div>
              {odjel_distribucije ? (
                <div>
                  <select className='dropdown' onChange={handleChangeOdjel_distribucijeOptions} name='' id=''>
                    {upoznavanjeOptions2.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className='add-employee-box__article__columns__item margin-top-2'>
                    <label htmlFor='odjel_distribucijeData'>Naglasak: </label>
                    <input
                      className='add-employee-box__article__columns__item__input'
                      type='text'
                      onChange={(e) => {
                        setOdjel_distribucijeData(e.target.value);
                      }}
                    />
                    <p className='add-employee-box__article__columns__item_message'>
                      Ukoliko postoje specifični projekti/procesi na koje je potrebno staviti naglasak navedite ih ovdje te ih podijelite znakom ";"
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </article> */}
        </section>

        {/* {stranica === true ? (
          <img src={prevPage} alt='' onClick={() => setStranica(!stranica)} />
        ) : (
          <img src={nextPage} alt='' onClick={() => setStranica(!stranica)} />
        )} */}

        <button
          type='button'
          onClick={() => {
            setStranica(!stranica);
            window.scrollTo(0, 0);
          }}
          className='button text-white margin-top-2'
          disabled={isAnyFalse()}
        >
          {stranica === true ? '<< Prethodna stranica ' : 'Sljedeća stranica >>'}
        </button>
        {isAnyFalse() && (
          <p className='margin-left-2 margin-right-2 text-center'>
            Portebno je ispuniti sve podatke i odabrati "Da" ili "Ne" na svim poljima kako bi se omogućio prelazak na drugu stranicu!
          </p>
        )}

        <button className={`button text-white margin-top-2 ${!stranica && 'hide'}`} type='submit' disabled={disabledButton}>
          Spremi
        </button>
      </form>
    </div>
  );
};

export default InputListPage;
