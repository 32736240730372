import useAxiosPrivate from '../common/hooks/useAxiosPrivate';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Reminder = (props: any) => {
  const axiosPrivate = useAxiosPrivate();
  const reminderBell = require('../assets/icons/reminderBell.png');

  const reminder = async (event: any) => {
    try {
      props.enableLoading();
      await axiosPrivate.get(`/notify/${props.employee.obj_id}/${event.target.alt}`).then(() => props.disableLoading());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tippy content='Pošalji podsjetnik za ispunjavanje zadataka'>
      <img className='requests-box__table__td__reminder-icon' src={reminderBell} alt={props.attribute} onClick={reminder} />
    </Tippy>
  );
};

export default Reminder;
