import React, { useEffect, useState } from 'react';
import { Employee } from '../common/types/types';
import useKorisnik from '../store/hooks/useKorisnik';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reminder from './Reminder';
import RequestApprovalComponent from './RequestApprovalComponent';
import { pretvoriDatumUFormatTablice } from '../common/utils/HelperFunction';

interface Props {
  employeeDetails: Employee;
}

const DetailsComponent = (props: Props) => {
  const checkedCheckbox = require('../assets/icons/checkedCheckbox.png');
  const uncheckedCheckbox = require('../assets/icons/uncheckedCheckbox.png');
  const arrowLeft = require('../assets/icons/arrowLeft2.png');
  const employee = props.employeeDetails;
  const korisnik = useKorisnik();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [completion, setCompletion] = useState(employee.completion);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let employeeArray: any[] = [
    employee.ldc && ['LDC', ...employee.ldc],
    employee.velika_lokacija && ['Velika lokacija', ...employee.velika_lokacija],
    employee.mala_lokacija && ['Mala lokacija', ...employee.mala_lokacija],
    employee.kontinental_hrvatska && ['Kontinemtalna hrvatska', ...employee.kontinental_hrvatska],
    employee.jadranska_hrvatska && ['Jadranska Hrvatska', ...employee.jadranska_hrvatska],
    employee.online_kanali_prodaje && ['Online kanali prodaje', ...employee.online_kanali_prodaje],
    employee.call_center && ['Call centar', ...employee.call_center],
    // employee.lean && ['Lean/Kaizen', ...employee.lean],
    employee.upravljanje_kategorijama && ['Upravljanje karegorijama', ...employee.upravljanje_kategorijama],
    employee.upravljanje_cijenima && ['Upravljanje cijenama', ...employee.upravljanje_cijenima],
    employee.maticni_podaci && ['Matični podaci', ...employee.maticni_podaci],
    employee.podrska_u_nabavi && ['Podrška u nabavi', ...employee.podrska_u_nabavi],
    employee.upravljanje_zalihom && ['Upravljanje zalihom', ...employee.upravljanje_zalihom],
    employee.transport && ['Transport', ...employee.transport],
    employee.WMS_tim && ['WMS', ...employee.WMS_tim],
    employee.gospodarski_program && ['Gospodarski program', ...employee.gospodarski_program],
    employee.alati_servisna_oprema && ['Alati i servisna oprema', ...employee.alati_servisna_oprema],
    employee.acc && ['ACC', ...employee.acc],
    employee.industrijski_program && ['Industrijski program', ...employee.industrijski_program],
    employee.tec && ['TEC', ...employee.tec],
    employee.klucni_kupci && ['Ključni kupci i natječaji', ...employee.klucni_kupci],
    // employee.usluge_u_prodaju && ['Usluge u prodaji', ...employee.usluge_u_prodaju],
    employee.izvozna_prodaja && ['Izvozna prodaja', ...employee.izvozna_prodaja],
    employee.poslovni_guma && ['Poslovanje guma', ...employee.poslovni_guma],
    employee.marketing && ['Marketing', ...employee.marketing],
    employee.crm && ['CRM', ...employee.crm],
    employee.korporativniUpravlje && ['Korporativno upravljanje', ...employee.korporativniUpravlje],
    employee.ljudksi_resursi && ['Ljudski resursi', ...employee.ljudksi_resursi],

    employee.informatika && ['Informatika', ...employee.informatika],
    employee.ICT_infrastruktura && ['ICT infrastruktura', ...employee.ICT_infrastruktura],
    employee.DTI && ['DTI', ...employee.DTI],
    employee.poslovna_inteligencija && ['Poslovna Inteligencija', ...employee.poslovna_inteligencija],
    employee.odrzavanje && ['Održavanje i investicije', ...employee.odrzavanje],
    employee.financije && ['Financije', ...employee.financije],
    // employee.odjel_sredisnje_nabave && ['Odjel središnje nabave', ...employee.odjel_sredisnje_nabave],
    employee.kontroling && ['Kontroling', ...employee.kontroling],
    // employee.odjel_distribrucije && ['Odjel distribucije', ...employee.odjel_distribrucije],
  ];

  let departmentsArray: any[] = [];
  employeeArray.map((department: Array<string> | null) => {
    if (department && department?.length > 1) departmentsArray = [...departmentsArray, department];
    return departmentsArray; //možda treba ukloniti
  });

  // let departmentsArraySorted: any[] = [];
  // odjeli.forEach((odjel: { id: number; value: string }) => {
  //   departmentsArray.forEach((department: Array<string>) => {
  //     if (department[0] == odjel.value) departmentsArraySorted = [...departmentsArraySorted, department];
  //     return departmentsArraySorted; //možda treb ukloniti
  //   });
  // });
  // console.log(departmentsArraySorted);

  const back = () => {
    navigate(-1);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
    toast.info('Obavijest poslana');
  };

  const disableLoadingAprovalRequest = (newCompletion: number) => {
    setCompletion(newCompletion);
    setLoading(false);
  };

  // const acceptRequest = async (employee: any) => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosPrivate.get(`/acceptReq/${employee.obj_id}`).then(() => {
  //       setCompletion(1);
  //       disableLoadingAprovalRequest();
  //       toast.success('Zahtjev je uspješno odobren!');
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const rejectRequest = async (employee: any) => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosPrivate.get(`/rejectReq/${employee.obj_id}`).then(() => {
  //       setCompletion(3);
  //       disableLoadingAprovalRequest();
  //       toast.success('Zahtjev je uspješno odbijen!');
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <article className='requests-box'>
      <img src={arrowLeft} className='button-back' alt='' onClick={back} />
      {loading ? (
        <div className='loading'>
          <ClimbingBoxLoader color={'#4f46e5'} loading={loading} size={30} />
        </div>
      ) : (
        <></>
      )}
      <h2>Detalji zaposlenika</h2>
      <h3>Ime: {employee.employeename}</h3>
      <p className='font-weight-6'>Radno mjesto: {employee.employeeposition}</p>
      <p className='font-weight-6'>Odjel: {employee.employeedepartment}</p>
      <p className='font-weight-6'>Datum početka rada: {pretvoriDatumUFormatTablice(employee.dateofemployment, 'Neispravni datum')}</p>

      <div className='margin-bottom-1 requests-box__buddy text-center'>
        <h4>Buddy</h4>
        <div className='requests-box__buddy__details'>
          <div className='requests-box__buddy_details__item'>
            <div className='margin-bottom-1'>Ime i prezime:</div>
            <p className='font-weight-6'>{employee.buddy}</p>
          </div>
          <div className='requests-box__buddy_details__item'>
            <div className='margin-bottom-1'>Odjel:</div>
            <p className='font-weight-6'>{employee.buddyposition}</p>
          </div>
          <div className='requests-box__buddy_details__item'>
            <div className='margin-bottom-1'>Email:</div>
            <p className='font-weight-6'>{employee.buddyemail}</p>
          </div>
        </div>
      </div>
      <table className='requests-box__table text-center'>
        <tbody>
          <tr>
            <th className='requests-box__table__td'>
              <span>Lista</span>
            </th>
            {/* napraviti klasu requests-box__table__th */}

            <th className='requests-box__table__td'>
              <span>Status</span>
            </th>
            {/* napraviti klasu requests-box__table__th */}

            {korisnik.isHr() && completion === 1 ? (
              <th className='requests-box__table__td'>
                <span>Podsjeti</span>
              </th>
            ) : (
              <></>
            )}
          </tr>
          {/* napraviti klasu requests-box__table__th */}

          {/*----------------------------------------------------------------------------------------------------------------------------------- */}

          {employee.computer.length ||
          employee.softwares.length ||
          employee.emailgroups.length ||
          employee.computer.length ||
          employee.phone.length ? (
            <tr className='margin-top-1 margin-bottom-1'>
              <td className='requests-box__table__td requests-box__table__td-master center-column'>
                {employee.computer.length ? (
                  <>
                    <p className='font-weight-7'>Računalo</p>
                    <tr>
                      <td>
                        {employee.computer.map((item: string, index: number) => {
                          return (
                            <li key={index} className='font-12'>
                              {item}
                            </li>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
                {employee.softwares.length ? (
                  <>
                    <p className='font-weight-7 margin-top-4'>Informatički programi</p>
                    <tr>
                      <td>
                        {employee.softwares.map((item: string, index: number) => {
                          return (
                            <li key={index} className='font-12'>
                              {item}
                            </li>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
                {employee.emailgroups.length ? (
                  <>
                    <p className='font-weight-7 margin-top-4'>Email grupe</p>
                    <tr>
                      <td>
                        {employee.emailgroups.map((item: string, index: number) => {
                          return (
                            <li key={index} className='font-12'>
                              {item}
                            </li>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
                {employee.sharefolders.length ? (
                  <>
                    <p className='font-weight-7 margin-top-4'>Pristup sharefolderima</p>
                    <tr>
                      <td>
                        {employee.sharefolders.map((item: string, index: number) => {
                          return (
                            <li key={index} className='font-12'>
                              {item}
                            </li>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
                {employee.phone.length ? (
                  <>
                    <p className='font-weight-7 margin-top-4'>Službeni mobitel</p>
                    <tr>
                      <td>
                        {employee.phone.map((item: string, index: number) => {
                          return (
                            <li key={index} className='font-12'>
                              {item}
                            </li>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.itBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='itStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.itBool && completion === 1 ? (
                <td>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='it' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <></>
          )}

          {/*----------------------------------------------------------------------------------------------------------------------------------- */}

          {employee.card.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <tr>
                  <td>
                    <p className='font-weight-7'>Kartica za ulazak</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    {employee.card.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.cardBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='cardStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.cardBool && completion === 1 ? (
                <td>
                  {/* <button name='card' onClick={reminder}></button> */}

                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='card' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}

          {employee.intelligence?.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>BI (Tabularna kocka)</p>
                <tr>
                  <td>
                    {employee.intelligence.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.intelligenceBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='intelligenceStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.intelligenceBool && completion === 1 ? (
                <td>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='intelligence' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}

          {employee.car.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>Službeni automobil</p>

                <tr>
                  <td>
                    {employee.car.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.carBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='carStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.carBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='car' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          {employee.visa.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>Visa kartica</p>

                <tr>
                  <td>
                    <li className='font-12'>{employee.visa}</li>
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.visaBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='visaStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.visaBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='visa' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          {employee.dms.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>DMS prava</p>

                <tr>
                  <td>
                    {employee.dms.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.dmsBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='dmsStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.dmsBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='dms' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          {employee.others.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>Ostalo</p>

                <tr>
                  <td>
                    {employee.others.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.othersBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='othersStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.othersBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='others' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          {employee.radni_stol?.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>Radni stol i stolica</p>
                <tr>
                  <td>
                    {employee.radni_stol.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.radni_stolBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='radniStolStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.radni_stolBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='radni_stol' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          {employee.vizitka?.length ? (
            <tr>
              <td className='requests-box__table__td center-column'>
                <p className='font-weight-7'>Vizitka</p>
                <tr>
                  <td>
                    {employee.vizitka.map((item: string, index: number) => {
                      return (
                        <li key={index} className='font-12'>
                          {item}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              </td>
              <td className='requests-box__table__td'>
                <img
                  className='requests-box__table__td__status-icon'
                  src={employee.vizitkaBool ? checkedCheckbox : uncheckedCheckbox}
                  alt='vizitkaStatus'
                ></img>
              </td>
              {korisnik.isHr() && !employee.vizitkaBool && completion === 1 ? (
                <td className='requests-box__table__td'>
                  <Reminder enableLoading={enableLoading} disableLoading={disableLoading} employee={employee} attribute='vizitka' />
                </td>
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <tr></tr>
          )}
          <tr>
            <td>
              <hr />
            </td>
            <td>
              <hr />
            </td>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            {departmentsArray.map((department: Array<string>, index: number) => {
              return (
                <td className='requests-box__table__td center-column' key={index}>
                  {department &&
                    department.map((item: string, index2: number) => {
                      if (index2 === 0) {
                        return (
                          <p key={index2} className='font-weight-7'>
                            {item && item}
                          </p>
                        );
                      } else {
                        return (
                          <li key={index2} className='font-12'>
                            {item && item}
                          </li>
                        );
                      }
                    })}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>

      {korisnik.isHr() && completion === 4 && (
        <div className='requests-box__aproval margin-top-2'>
          <RequestApprovalComponent enableLoading={enableLoading} onFinish={disableLoadingAprovalRequest} employee={employee} />
        </div>
      )}

      <ToastContainer />
    </article>
  );
};

export default DetailsComponent;
