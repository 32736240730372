import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddEmployee from './pages/AddEmployee';
import { Dashboard } from './pages/Dashboard';
import Details from './pages/Details';
import { Landing } from './pages/Landing';
import './sass/main.scss';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Landing />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='dashboard/details' element={<Details />} />
        <Route path='dashboard/addEmployee' element={<AddEmployee />} />
        <Route path='statusList' element={<div>Status List</div>} />
        <Route path='inputList' element={<div>Input List</div>} />
        <Route path='*' element={<div>Error</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
