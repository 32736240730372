import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../common/hooks/useAxiosPrivate';
import { Modal } from 'antd';

const RemoveEmployee = (props: any) => {
  const axiosPrivate = useAxiosPrivate();
  const deleteEmployee = require('../assets/icons/remove.png');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const remove = async () => {
    setIsModalOpen(false);
    try {
      props.enableLoading();
      await axiosPrivate.delete(`/employee/${props.employee.obj_id}/`).then(() => {
        props.onFinish();
        toast.success('Zaposlenik uspješno uklonjen.');
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tippy content='Obriši'>
        <img className='requests-box__table__td__delete-employee' src={deleteEmployee} onClick={showModal} alt='delete' />
      </Tippy>
      <Modal title={`Brisanje zapisa o zaposleniku: ${props.employee.employeename}`} open={isModalOpen} onOk={remove} onCancel={handleCancel}>
        <p>Jeste li sigurni da želite obrisati odabranog zaposlenika?</p>
      </Modal>
    </>
  );
};

export default RemoveEmployee;
