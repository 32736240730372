import axios, { BASE_URL } from '../API';
import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const LOGIN_URL = '/token/';

export const Login = () => {
  useEffect(() => {
    // Applying on mount
    document.body.classList.add('login-background');
    // document.body.style.overflow = "hidden";
    // document.body.style.background =
    //   "linear-gradient(to right, #3b2dcf 1%, #a91b78 100%)";
    // Applying on unmount
    return () => {
      document.body.classList.remove('login-background');
      // document.body.style.overflow = "visible";
      // document.body.style.background = "#f1f5f8";
    };
  }, []);

  const { setAuth }: any = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/dashboard';

  const userRef = useRef<any>();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const loadingSpinner = require('../assets/icons/loadingSpinner.gif');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [username, password]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setDisabledButton(true);
    try {
      const response = await axios.post(LOGIN_URL, {
        username: username,
        password: password,
        // username: "admin",
        // password: "haven747",
      });
      // .then(afterLoginRequest)
      // .finally(korisnik.osvjeziKorisnika);
      const accessToken = response?.data?.access;
      localStorage.setItem('access', accessToken); //moguce da ne radi zbog stale state-a
      const refreshToken = response?.data?.refresh;
      localStorage.setItem('refresh', refreshToken); //moguce da ne radi zbog stale state-a
      setAuth({ username, password, accessToken, refreshToken });
      setUsername('');
      setPassword('');
      // korisnik.osvjeziKorisnika();
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setDisabledButton(false);
    }
  };

  // const afterLoginRequest = (response: any) => {
  //   const accessToken = response?.data?.access;
  //   localStorage.setItem('access', accessToken); //moguce da ne radi zbog stale state-a
  //   const refreshToken = response?.data?.refresh;
  //   localStorage.setItem('refresh', refreshToken); //moguce da ne radi zbog stale state-a
  //   setAuth({ username, password, accessToken, refreshToken });
  //   setUsername('');
  //   setPassword('');
  //   setSuccess(true);
  //   navigate(from, { replace: true });
  // };

  return (
    <div className='login'>
      <div className='login-box'>
        <form className='login-box__form' onSubmit={handleSubmit}>
          <h2>HR App</h2>
          <div className='login-box__form__item'>
            <label htmlFor='username' className='login-box__form__item__label'>
              Korisničko ime
            </label>
            <input
              className={`login-box__form__item__input ${!success ? 'login-box__form__item__input-error' : ''}`}
              placeholder='Enter username'
              type='text'
              ref={userRef}
              autoComplete='off'
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
            />
          </div>
          <div className='login-box__form__item'>
            <label htmlFor='password' className='login-box__form__item__label'>
              Lozinka
            </label>
            <input
              className={`login-box__form__item__input ${!success ? 'login-box__form__item__input-error' : ''}`}
              placeholder='Enter password'
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            {!success && <span className='font-13 text-danger'>Unijeli ste pogrešno korisničko ime ili lozinku.</span>}
          </div>
          <div className=''>
            <button
              className={`${disabledButton ? 'login-box__form__button-disabled' : ''} login-box__form__button text-white`}
              type='submit'
              disabled={disabledButton}
            >
              Prijava
            </button>
            {disabledButton && <img src={loadingSpinner} alt='' />}
          </div>
          <p className='login-box__form__forgot-password'>
            Zaboravljena{' '}
            <a className='login-box__form__forgot-password__link' href={BASE_URL + '/password_reset'}>
              lozinka?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};
