import React, { useEffect } from 'react';
import Employees from '../components/Employees';
import useKorisnik from '../store/hooks/useKorisnik';

export const Dashboard = () => {
  return (
    <section>
      <Employees />
    </section>
  );
};
