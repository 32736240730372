import { useAppDispatch, useAppSelector } from './hooks';
import { setKorisnik } from '../slice/KorisnikSlice';
import { axiosPrivate } from '../../API';

interface UseKorisnik {
  osvjeziKorisnika: () => void;
  isHr: () => boolean;
  isManager: () => boolean;
}

function useKorisnik() {
  const korisnik = useAppSelector((state) => state.korisnik);
  const controller = new AbortController();
  const dispatch = useAppDispatch();

  const osvjeziKorisnika = async () => {
    await axiosPrivate
      .get('/me/')
      .then((response) => {
        dispatch(setKorisnik(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
    // await KorisnikService.getTrenutni()
    //   .then((response) => {
    //     dispatch(setKorisnik(response.data));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const isHr = (): boolean => {
    return korisnik.role.toLowerCase().includes('human resource');
  };

  const isManager = (): boolean => {
    return korisnik.role.toLocaleLowerCase().includes('manager');
  };

  return {
    osvjeziKorisnika: osvjeziKorisnika,
    isHr: isHr,
    isManager: isManager,
  } as UseKorisnik;
}
export default useKorisnik;
