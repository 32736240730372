import axios from 'axios';
export const BASE_URL = 'https://hr-api-container.azurewebsites.net/api';
// export const BASE_URL = 'http://127.0.0.1:8000/api';

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    contentType: 'application/json',
  },
  withCredentials: true,
});

// interface Params {
//   baseUrl: string;
//   headers: any;
//   method: string;
// }

// const config: Params = {
//   baseUrl: "http://127.0.0.1:8000/api/",
//            "https://hr-app-api.azurewebsites.net/api"
//   headers: {
//     contentType: "application/json",
//     Authorization: `Bearer ${"token variable"}`,
//   },
//   method: "post",
// };

// export const API = () => {
//   return <div>API</div>;
// };
