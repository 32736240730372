import { useState, useEffect } from 'react';
import { Employee, EmployeeTable } from '../common/types/types';
import useAxiosPrivate from '../common/hooks/useAxiosPrivate';
import { pretvoriDatumUFormatTablice, pretvoriStatusZahtjevaUFormatTablice } from '../common/utils/HelperFunction';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks/hooks';
import { setRecivedEmployees } from '../store/slice/EmployeeSlice';
import useKorisnik from '../store/hooks/useKorisnik';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import RequestApprovalComponent from './RequestApprovalComponent';
import RemoveEmployee from './RemoveEmployee';

const Employees = () => {
  const [employees, setEmployees] = useState<Array<Employee>>(useAppSelector((state) => state.employees.recivedEmployees));
  const [loading, setLoading] = useState<boolean>(false);

  const korisnik = useKorisnik();
  const axiosPrivate = useAxiosPrivate();
  // const refresh = useRefreshToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoutIcon = require('../assets/icons/logout.png');
  const addEmployee = require('../assets/icons/addEmp.png');

  const enableLoading = () => {
    setLoading(true);
  };

  const employeesToEmployeeTable = (readyEmployees: Employee[]): void => {
    let employeesTable: EmployeeTable[] = [];
    for (let employee of readyEmployees) {
      employeesTable.push({ ...employee, key: employee.obj_id });
      dispatch(setRecivedEmployees([...employeesTable]));
    }
  };

  const getEmployees = async () => {
    try {
      axiosPrivate.get('/employee/', {}).then((response) => {
        setEmployees(response.data);

        employeesToEmployeeTable(response.data as Array<Employee>);
      });
    } catch (err) {
      console.log(err);
      navigate('/');
    }
  };
  useEffect(() => {
    korisnik.osvjeziKorisnika();
    getEmployees();
  }, []);

  const onFinishApproval = () => {
    getEmployees();
    setLoading(false);
  };

  const LogOut = () => {
    localStorage.setItem('access', '');
    localStorage.setItem('refresh', '');
    navigate('/');
  };

  return (
    <>
      <article className='requests-box'>
        {loading ? (
          <div className='loading'>
            <ClimbingBoxLoader color={'#4f46e5'} loading={loading} size={30} />
          </div>
        ) : (
          <></>
        )}
        {/* <button className='requests-box__logout button text-white' onClick={LogOut}>
          Odjava
        </button> */}
        <Tippy content='Odjava'>
          <img src={logoutIcon} className='requests-box__logout' alt='logout' onClick={LogOut} />
        </Tippy>
        <h2>Lista zaposlenika</h2>
        <div className='request-box__header margin-bottom-2'>
          <h3>Onboarding zahtjevi</h3>
          <Tippy content='Dodaj zaposlenika'>
            <Link to='/dashboard/addEmployee'>
              <img src={addEmployee} alt='addEmployee' />
            </Link>
          </Tippy>
        </div>
        <table className='requests-box__table margin-bottom-5'>
          <tbody>
            <tr>
              <th className='requests-box__table__td text-left'>
                <span>Ime i prezime</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}

              <th className='requests-box__table__td text-left'>
                <span>Status</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
              <th className='hide-small requests-box__table__td text-left'>
                <span>Predavatelj zahtjeva</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
              <th className='hide-small requests-box__table__td text-left'>
                <span>Datum zahtjeva</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
            </tr>
            {employees?.length ? (
              employees.map(
                (employee: Employee, index: number) =>
                  (employee.completion === 1 || employee.completion === 2) && (
                    <tr key={index}>
                      <td className='requests-box__table__td'>{employee?.employeename}</td>
                      <td className='requests-box__table__td'>
                        <div className={`requests-box__table__td-status-${employee?.completion}`}>
                          {pretvoriStatusZahtjevaUFormatTablice(employee?.completion, 'Invalid status')}
                        </div>
                      </td>
                      <td className='hide-small requests-box__table__td'>{employee.fullName}</td>
                      <td className='hide-small requests-box__table__td'>{pretvoriDatumUFormatTablice(employee.date, 'Neispravni datum')}</td>
                      <td>
                        <Link
                          className='requests-box__table__td requests-box__table__td__details'
                          to='/dashboard/details'
                          state={{ from: employee.obj_id }}
                        >
                          Detalji
                        </Link>
                      </td>
                      {employee.completion === 2 && (
                        <td className='requests-box__table__td'>
                          <RemoveEmployee enableLoading={enableLoading} onFinish={onFinishApproval} employee={employee} />
                        </td>
                      )}
                    </tr>
                  )
              )
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>

        <div className='request-box__header margin-bottom-2'>
          <h3>Lista čekanja</h3>
        </div>
        <table className='requests-box__table'>
          <tbody>
            <tr>
              <th className='requests-box__table__td text-left'>
                <span>Ime i prezime</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}

              <th className='requests-box__table__td text-left'>
                <span>Status</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
              <th className='hide-small requests-box__table__td text-left'>
                <span>Predavatelj zahtjeva</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
              <th className='hide-small requests-box__table__td text-left'>
                <span>Datum zahtjeva</span>
              </th>
              {/* napraviti klasu requests-box__table__th */}
            </tr>
            {employees?.length ? (
              employees.map(
                (employee: Employee, index: number) =>
                  employee.completion !== 1 &&
                  employee.completion !== 2 && (
                    <tr key={index}>
                      <td className='requests-box__table__td'>{employee?.employeename}</td>

                      {korisnik.isHr() && employee.completion === 4 ? (
                        <td className='requests-box__table__td'>
                          <RequestApprovalComponent enableLoading={enableLoading} onFinish={onFinishApproval} employee={employee} />
                        </td>
                      ) : (
                        <td className='requests-box__table__td'>
                          <div className={`requests-box__table__td-status-${employee?.completion}`}>
                            {pretvoriStatusZahtjevaUFormatTablice(employee?.completion, 'Neispravni status')}
                          </div>
                        </td>
                      )}
                      <td className='hide-small requests-box__table__td'>{employee.fullName}</td>
                      <td className='hide-small requests-box__table__td'>{pretvoriDatumUFormatTablice(employee.date, 'Neispravni datum')}</td>
                      <td>
                        <Link
                          className='requests-box__table__td requests-box__table__td__details'
                          to='/dashboard/details'
                          state={{ from: employee.obj_id }}
                        >
                          Detalji
                        </Link>
                      </td>
                      {employee.completion === 3 && (
                        <td className='requests-box__table__td'>
                          <RemoveEmployee enableLoading={enableLoading} onFinish={onFinishApproval} employee={employee} />
                        </td>
                      )}
                    </tr>
                  )
              )
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>

        {/* <button onClick={() => refresh()}>Refresh</button> */}
        <ToastContainer />
      </article>
    </>
  );
};

export default Employees;
