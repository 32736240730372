import { toast } from 'react-toastify';
import useAxiosPrivate from '../common/hooks/useAxiosPrivate';
import { Employee } from '../common/types/types';

interface Props {
  employee: Employee;
  onFinish: (completion: number) => void;
  enableLoading: () => void;
}
const RequestApprovalComponent = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const checkedCheckbox = require('../assets/icons/checkedCheckbox.png');
  const rejectButton = require('../assets/icons/rejectButton.png');
  const acceptRequest = async (employee: any) => {
    try {
      props.enableLoading();
      await axiosPrivate.get(`/acceptReq/${employee.obj_id}`).then(() => {
        props.onFinish(1);
        toast.success('Zahtjev je uspješno odobren!');
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rejectRequest = async (employee: any) => {
    try {
      props.enableLoading();
      await axiosPrivate.get(`/rejectReq/${employee.obj_id}`).then(() => {
        props.onFinish(3);
        toast.success('Zahtjev je uspješno odbijen!');
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <img
        onClick={() => acceptRequest(props.employee)}
        className='requests-box__table__td__approval-icon'
        src={checkedCheckbox}
        alt='acceptRequest'
      ></img>
      <img
        onClick={() => rejectRequest(props.employee)}
        className='requests-box__table__td__approval-icon'
        src={rejectButton}
        alt='rejectRequest'
      ></img>
    </>
  );
};

export default RequestApprovalComponent;
