import { combineReducers, configureStore } from '@reduxjs/toolkit';
import employeesReducer from './slice/EmployeeSlice';
import korisnikReducer from './slice/KorisnikSlice';
import storage from 'redux-persist/lib/storage';
import { REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import API from '../API';

const reducers = combineReducers({
  employees: employeesReducer,
  korisnik: korisnikReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const customMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === REHYDRATE) {
    next(action);
    API.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access');
  } else {
    next(action);
  }
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(customMiddleware),
});

export let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
