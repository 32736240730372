import { axiosPrivate } from '../../API';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';
import { useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth }: any = useAuth();
  let brojac: number = 0;
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (config.headers && !config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const accessToken = localStorage.getItem('access');
        if (error.config.headers.Authorization === 'Bearer ' + accessToken) {
          brojac++;
        }
        if (brojac === 20) {
          localStorage.setItem('access', '');
          localStorage.setItem('refresh', '');
          navigate('/');
        }

        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          brojac = 0;
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
