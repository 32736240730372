import { useNavigate } from 'react-router-dom';
import axios, { axiosPrivate } from '../../API';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { auth }: any = useAuth();
  const { setAuth }: any = useAuth();

  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const response = await axiosPrivate.post('token/refresh/', {
        refresh: localStorage.getItem('refresh'),
      });
      setAuth((prev: any) => {
        localStorage.setItem('access', response.data.access);
        return { ...prev, accessToken: response.data.access };
      });
      return response.data.access;
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  };
  return refresh;
};

export default useRefreshToken;
