import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee, EmployeeTable } from '../../common/types/types';

interface Employees {
  recivedEmployees: EmployeeTable[];
}

const initialState: Employees = {
  recivedEmployees: [],
};

const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setRecivedEmployees: (state, action: PayloadAction<EmployeeTable[]>) => {
      state.recivedEmployees = action.payload;
      //   return {
      //     ...state,
      //     recivedEmployees: action.payload,
      //   };
    },
  },
});

export const { setRecivedEmployees } = employeeSlice.actions;

export default employeeSlice.reducer;
